import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AmountTypeDropdown from '../Selectors/AmountType'
import EventTypeDropdown from '../Selectors/EventType'
import SiteDropdown from '../Selectors/Site'


export default function ItemEventCountMismatch(props) {
    const [multiple, setMulitple] = useState(0);
    const [site, setSite] = useState();

    useEffect(() => {
        setSite(props.queryParameters.site)
        if (props.queryParameters.multiple !== undefined) {
            setMulitple(props.queryParameters.multiple)
        }
    }, [])

    useEffect(() => {
        var sql = ""
        if(multiple === 0) {
            if (site === undefined || site.length === 0) {
                sql = `Select shipment_id as 'Shipment ID', event_count as 'Event Count', item_count as 'Item Count', parent_item_count as 'Parent Shipment Item Count' from (select distinct de_shipment_id as shipment_id, ds_parentid as parent_shipment_id from DBA.disp_ship left outer join DBA.disp_events on disp_ship.ds_id = disp_events.de_shipment_id left outer join DBA.companies on companies.co_id = de_site where de_event_type in (<event_type>) and terminationlocation = 'T' and de_conf = 'T' and <date> and disp_ship.ds_status not in ('C', 'D')) as shipments LEFT JOIN (SELECT count(de_id) as event_count, de_shipment_id FROM DBA.disp_ship left outer join DBA.disp_events on disp_ship.ds_id = disp_events.de_shipment_id left outer join DBA.companies on companies.co_id = de_site where de_event_type in (<event_type>) group by de_shipment_id) as mount ON mount.de_shipment_id = shipments.shipment_id LEFT JOIN (SELECT count(di_item_id) as item_count, di_shipment_id FROM DBA.disp_items where AmountType in (<amount_type>) group by di_shipment_id) as chassis ON chassis.di_shipment_id = shipments.shipment_id LEFT JOIN (SELECT count(di_item_id) as parent_item_count, di_shipment_id FROM DBA.disp_items where AmountType in (<amount_type>)  group by di_shipment_id) as parent_chassis on parent_chassis.di_shipment_id = shipments.parent_shipment_id where (event_count != item_count and event_count != parent_item_count) or (event_count is null and item_count > 0) or ((item_count is null and parent_item_count is null) and event_count > 0)`
            } else {
                sql = `Select shipment_id as 'Shipment ID', event_count as 'Event Count', item_count as 'Item Count', parent_item_count as 'Parent Shipment Item Count' from (select distinct de_shipment_id as shipment_id, ds_parentid as parent_shipment_id from DBA.disp_ship left outer join DBA.disp_events on disp_ship.ds_id = disp_events.de_shipment_id left outer join DBA.companies on companies.co_id = de_site where de_event_type in (<event_type>) and terminationlocation = 'T' and de_conf = 'T' and <date> and disp_ship.ds_status not in ('C', 'D')) as shipments LEFT JOIN (SELECT count(de_id) as event_count, de_shipment_id FROM DBA.disp_ship left outer join DBA.disp_events on disp_ship.ds_id = disp_events.de_shipment_id left outer join DBA.companies on companies.co_id = de_site where de_event_type in (<event_type>) and de_site in (<site>) group by de_shipment_id) as mount ON mount.de_shipment_id = shipments.shipment_id LEFT JOIN (SELECT count(di_item_id) as item_count, di_shipment_id FROM DBA.disp_items where AmountType in (<amount_type>) group by di_shipment_id) as chassis ON chassis.di_shipment_id = shipments.shipment_id LEFT JOIN (SELECT count(di_item_id) as parent_item_count, di_shipment_id FROM DBA.disp_items where AmountType in (<amount_type>)  group by di_shipment_id) as parent_chassis on parent_chassis.di_shipment_id = shipments.parent_shipment_id where (event_count != item_count and event_count != parent_item_count) or (event_count is null and item_count > 0) or ((item_count is null and parent_item_count is null) and event_count > 0)`
            }
        } else if (multiple === 1) {
            sql = `select e1.ds_id as 'Shipment ID' FROM (
                select ds_id, de_event_type, de_ship_seq, de_site, ds_parentid from dba.disp_ship
                left join dba.disp_events
                where ds_status not in ('C', 'D') and de_conf='T'
                and de_site in (<site>) and de_event_type in (<event_type>) and <date>) e1
                join(
                select ds_id, de_event_type, de_ship_seq, de_site from dba.disp_ship
                left join dba.disp_events
                where ds_status not in ('C', 'D') and de_conf='T'
                and de_site in (<site_2>) and de_event_type in (<event_type_2>)
                ) e2 on e1.ds_id = e2.ds_id and e1.de_ship_seq = e2.de_ship_seq - 1
                left join(
                SELECT count(di_item_id) as item_count, di_shipment_id
                FROM DBA.disp_items
                where AmountType in (<amount_type>)
                group by di_shipment_id
                ) items on items.di_shipment_id = e1.ds_id
                left join(
                SELECT count(di_item_id) as item_count, di_shipment_id
                FROM DBA.disp_items
                where AmountType in (<amount_type>)
                group by di_shipment_id
                ) parent_items on parent_items.di_shipment_id = e1.ds_parentid
                where items.item_count is null and  parent_items.item_count is null
                order by e1.ds_id desc`
        }
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [site, multiple])

    const handleChange = (event) => {
        if (event.target.name === 'site') {
            setSite(event.target.value)
        }
        props.handleChange(event);
    };

    const handleMultiple = ({ target }) => {
        const { name, checked } = target;
        var value;
        if (checked) {
            value = 1
        } else {
            value = 0
        }
        setMulitple(value)
        props.handleChangeCheckbox({ target });
    };

    return (
        <>
            <Row>
                {multiple === 0 ?
                <Col className="align-self-center">    
                    Look for shipments where the count of these items doesn't match the count of these events.<br />Optionally provide a list of sites that the events must be at.
                </Col> :
                <Col className="align-self-center">
                    Look for shipments where Event 1 and Event 2 happen sequentially at their respective locations and don't have the Amount Type specified.
                </Col>
                }
            </Row>
            <Row>
                <Col>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={multiple === 1}
                                onChange={handleMultiple}
                                name="multiple"
                                color="primary"
                            />
                        }
                        label="Multiple Events"
                    />
                </Col>
            </Row>
            {multiple === 0 ?
            <>
                <Row>
                    <Col>
                        <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                    </Col>
                    <Col>
                        <EventTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SiteDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple />
                    </Col>
                </Row>
            </> : 
            <>
                <Row>
                    <Col>
                        <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <EventTypeDropdown displayName="Event 1" handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                    </Col>
                    <Col>
                        <SiteDropdown displayName="Sites 1" handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <EventTypeDropdown displayName="Event 2" name="event_type_2" handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                    </Col>
                    <Col>
                        <SiteDropdown displayName="Sites 2" name="site_2" handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                    </Col>
                </Row>
            </>
            }
        </>
    );
}