import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import AmountTypeDropdown from '../Selectors/AmountType'

export default function MultipleItems(props) {

    const [amountType, setAmountType] = useState(props.queryParameters.amount_type);

    useEffect(() => {
        var sql = ""
        if (amountType === undefined || amountType.length === 0) {
            sql = `SELECT di_shipment_id as 'Shipment ID', Name as 'Item AmountType' FROM DBA.disp_ship left outer join DBA.disp_items on disp_ship.ds_id = disp_items.di_shipment_id left outer join DBA.AmountType on AmountType.id = disp_items.AmountType where <date> and ds_status not in ('C', 'D') group by di_shipment_id, Name having count(AmountType) > 1 order by di_shipment_id`
        } else {
            sql = `SELECT di_shipment_id as 'Shipment ID', Name as 'Item AmountType' FROM DBA.disp_ship left outer join DBA.disp_items on disp_ship.ds_id = disp_items.di_shipment_id left outer join DBA.AmountType on AmountType.id = disp_items.AmountType where <date> and ds_status not in ('C', 'D') and id not in (<amount_type>) group by di_shipment_id, Name having count(AmountType) > 1 order by di_shipment_id`
        }
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [amountType])

    const handleChange = (event) => {
        if (event.target.name === 'amount_type') {
            setAmountType(event.target.value)
        }
        props.handleChange(event);
    };

    return (
        <>
            <Row>
                <Col className="align-self-center">All items should only be on a shipment ONCE. If there are multiple items on a shipment this will alert.<br/>
                You can optionally ignore certain amount types that are allowed on a shipment more than once.</Col>
            </Row>
            <Row>
                <Col>
                    <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple />
                </Col>
            </Row>
        </>
    );
}