import React, { useContext, useState } from 'react';
import { AccountContext } from '../../auth/Accounts';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loader from '../../Loading';
import { withSnackbar } from '../../HOC/Alert'


function Login (props) {
    const [formState, setFormState] = useState({});
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [userData, setUserData] = useState(null);
    const [userSession, setUserSession] = useState(null);
    const [loading, setLoading] = useState(false);

    const { authenticate } = useContext(AccountContext);

    const handleSubmit = (event) => {
        event.preventDefault();
        authenticate(formState.username, formState.password)
            .then(({ data, new_password_required, user_session}) => {
                setNewPasswordRequired(new_password_required);
                setUserData(data);
                setUserSession(user_session);
                if (!new_password_required) {
                    props.handleLogin();
                }
                setLoading(false)
            })
            .catch(err => {
                props.showAlert("Failed to login: \n" + err.message, "error");
                console.error('Failed to login!', err);
                setLoading(false)
            })
        setLoading(false)
    }
    
    const handleUpdatePassword = (event) => {
        event.preventDefault();
        if (formState.new_password !== formState.new_password_verify) {
            props.showAlert("Passwords don't match!", "error");
            return;
        }
        const userAttributes = {
            'email': userData.email,
            'given_name': formState.first_name,
            'family_name': formState.last_name
        }
        // console.log(userAttributes);
        userSession.completeNewPasswordChallenge(formState.new_password, userAttributes, {
            onSuccess: (result) => {
                console.log("Successfully set new password", result);
                authenticate(formState.username, formState.new_password)
                    .then(({ data, new_password_required, user_session }) => {
                        console.log('Logged in!', data);
                        setNewPasswordRequired(new_password_required);
                        setUserData(data);
                        setUserSession(user_session);
                        props.handleLogin();
                        setLoading(false)
                    })
                    .catch(err => {
                        props.showAlert(err.message, "error");
                        console.error('Failed to login!', err);
                    })
            },
            onFailure: (err) => {
                props.showAlert(err.message, "error")
            }
        })
        setLoading(false)
    }
    const handleChange = ({ target }) => {
        const { name, value } = target;
        setFormState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const useStyles = makeStyles((theme) => ({
        row: {
            marginTop: theme.spacing(15),
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(10),
                height: theme.spacing(20)
            },
            width: theme.spacing(70),
            backgroundColor: 'white'
        },
        paperverify: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(10),
                height: theme.spacing(50)
            },
            width: theme.spacing(70),
            backgroundColor: 'white'
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));
    const classes = useStyles();
    if (newPasswordRequired) {
        return (
            <Container>
                <Row className={classes.row}>
                    <Paper className={classes.paperverify} elevation={3}>
                        <Form className={classes.form} onSubmit={(event) => {
                            setLoading(true, handleUpdatePassword(event));
                        }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                key="username"
                                name="username"
                                disabled
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="first_name"
                                label="First Name"
                                key="fn"
                                name="first_name"
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="last_name"
                                label="Last Name"
                                key="ln"
                                name="last_name"
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="pw1"
                                label="New Password"
                                key="pw1"
                                name="new_password"
                                type="password"
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                error={formState.new_password !== formState.new_password_verify && formState.new_password_verify !== undefined}
                                helperText={formState.new_password !== formState.new_password_verify && formState.new_password_verify !== undefined ? "Passwords don't match." : ""}
                                id="pw2"
                                label="Verify Password"
                                key="pw2"
                                name="new_password_verify"
                                type="password"
                                onChange={handleChange}
                            />
                            {loading ? <Loader /> :
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={formState.new_password !== formState.new_password_verify}
                                    className={classes.submit}
                                >
                                    Set New Password
                            </Button>
                            }
                        </Form>
                    </Paper>
                </Row>
            </Container>
        )
    } else {
        return (
            <Container>
                <Row className={classes.row}>
                    <Paper className={classes.paper}>
                        <Form className={classes.form} onSubmit={(event) => {
                            setLoading(true, handleSubmit(event));
                        }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                key="username"
                                name="username"
                                autoComplete="DrayDrUsername"
                                autoFocus
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                key="pw"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                            {loading ? <Loader /> : 
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Sign In
                            </Button>}
                        </Form>
                    </Paper>
                </Row>
            </Container>
        );
    }
}

export default withSnackbar(Login);