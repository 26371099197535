import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function OriginDestinationAtSameLocation(props) {

    useEffect(() => {
        const sql = `SELECT ds_id as 'Shipment ID' FROM dba.disp_ship LEFT JOIN dba.disp_events where <date> and ds_status not in ('C', 'D') and ds_origin_id = ds_findest_id group by ds_id, ds_origin_id, de_site having CASE WHEN de_site != ds_origin_id THEN 1 ELSE 0 END CASE >= 1 order by ds_id desc`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will show you shipments where the origin and destination are at the same location. It will ignore certain shipments where every event is at either the origin or destination. For example it'll ignore a Hook/Drop at the same location, but if there were a deliver in between it would show here.</Col>
            </Row>
        </>
    );
}