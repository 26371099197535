import React, { useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AmountTypeDropdown from '../Selectors/AmountType'

export default function ItemsAtOriginOrDestination(props) {

    const [ignoreSingleDriverShipments, setIgnoreSingleDriverShipments] = useState(props.queryParameters.ignoreSingleDriverShipments);

    useEffect(() => {
        var sql;
        if (ignoreSingleDriverShipments) {
            sql = `select distinct de_shipment_id as 'Shipment ID', di_description as 'Item Description'
            from DBA.disp_ship 
            left outer join DBA.disp_events de on disp_ship.ds_id = de.de_shipment_id  
            left outer join DBA.disp_items on disp_items.di_shipment_id = disp_ship.ds_id
            where <date>
            and AmountType in (<amount_type>) 
            and disp_ship.ds_status not in ('C', 'D')
            and (
            select de_site from dba.disp_events de2 where de_ship_seq = di_pu_event and de2.de_shipment_id = de.de_shipment_id
            ) in (ds_origin_id, ds_findest_id)
            group by de_shipment_id, di_description
            having count(distinct de_driver) > 1
            order by de_shipment_id desc`
        } else {
            sql = `select distinct de_shipment_id as 'Shipment ID', di_description as 'Item Description'
            from DBA.disp_ship 
            left outer join DBA.disp_events de on disp_ship.ds_id = de.de_shipment_id  
            left outer join DBA.disp_items on disp_items.di_shipment_id = disp_ship.ds_id
            where <date>
            and AmountType in (<amount_type>) 
            and disp_ship.ds_status not in ('C', 'D')
            and (
            select de_site from dba.disp_events de2 where de_ship_seq = di_pu_event and de2.de_shipment_id = de.de_shipment_id
            ) in (ds_origin_id, ds_findest_id)
            group by de_shipment_id, di_description
            order by de_shipment_id desc`
        }
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        props.handleChange(event);
    };

    const handleInverted = ({ target }) => {
        const { name, checked } = target;
        var value;
        if (checked) {
            value = 1
        } else {
            value = 0
        }
        setIgnoreSingleDriverShipments(value)
        props.handleChangeCheckbox({ target });
    };

    return (
        <div>
            <Row>
                <Col className="align-self-center">Look for shipments that have these items assigned to events at the Origin or Destination...</Col>
            </Row>
            <Row>
                <Col>
                    <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={ignoreSingleDriverShipments === 1}
                                onChange={handleInverted}
                                name="ignoreSingleDriverShipments"
                                color="primary"
                            />
                        }
                        label="Ignore Single Driver Shipments"
                    />
                </Col>
            </Row>
        </div>
    );
}