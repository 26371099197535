import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


import BillToDropdown from '../Selectors/BillTo'

export default function TerminationLocationMismatch(props) {

    const [billTos, setBillTos] = useState(props.queryParameters.billto);

    useEffect(() => {
        var sql;
        if (billTos === undefined || billTos.length === 0) {
            sql = `select ds_id as 'Shipment ID', movecode as 'Import/Export', ShipmentOrigin.terminationlocation as OriginTermination, ShipmentDest.terminationlocation as DestinationTermination from DBA.disp_ship left outer join DBA.companies ShipmentOrigin on disp_ship.ds_origin_id = ShipmentOrigin.co_id left outer join DBA.companies ShipmentDest on disp_ship.ds_findest_id = ShipmentDest.co_id where <date> and ds_status not in ('C', 'D') and ( (movecode = 'I' and (OriginTermination != 'T' or OriginTermination is null)) or (movecode = 'E' and (DestinationTermination != 'T' or DestinationTermination is null))) and (OriginTermination = 'T' or DestinationTermination = 'T')`
        } else {
            sql = `select ds_id as 'Shipment ID', movecode as 'Import/Export', ShipmentOrigin.terminationlocation as OriginTermination, ShipmentDest.terminationlocation as DestinationTermination from DBA.disp_ship left outer join DBA.companies ShipmentOrigin on disp_ship.ds_origin_id = ShipmentOrigin.co_id left outer join DBA.companies ShipmentDest on disp_ship.ds_findest_id = ShipmentDest.co_id where <date> and ds_status not in ('C', 'D') and ds_billto_id not in (<billto>) and ( (movecode = 'I' and (OriginTermination != 'T' or OriginTermination is null)) or (movecode = 'E' and (DestinationTermination != 'T' or DestinationTermination is null))) and (OriginTermination = 'T' or DestinationTermination = 'T')`
        }
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [billTos])

    const handleChange = (event) => {
        if (event.target.name === 'billto') {
            setBillTos(event.target.value)
        }
        props.handleChange(event);
    };

    return (
        <div>
            <Row>
                <Col className="align-self-center">Look for Import and Export shipments where the origin or destination respectively isn't a termination location. You can also optionally ignore shipments if the BillTo is in this list.</Col>
            </Row>
            <Row>
                <Col>
                    <BillToDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple />
                </Col>
            </Row>
        </div>
    );
}