import React, { Component } from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';

class DetentionPage extends Component {
    render() {
        return (
            <Jumbotron>
                <h1>Detention</h1>
                <hr /><br />
                <p>Do some stuff</p>
            </Jumbotron>
        );
    }
}

export default DetentionPage;