import React, { useEffect, useContext, useState } from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';
import CardColumns from 'react-bootstrap/CardColumns';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Box, Container } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import ValidationQuery from './ValidationQuery'
import ReleaseDateWithin12Hours from './ReleaseWithin12Hours'
import DatePicker from '../../DatePicker'
import DateTypeDropdown from '../../DateTypeDropdown'
import API from '../../../api';
import { AccountContext } from '../../auth/Accounts';
import { withSnackbar } from '../../HOC/Alert'
import Loader from '../../Loading';
import CountDownTimer from '../../CountDownTimer';
import EmailSnapshotModal from './EmailSnapshotModal';


function ShipmentValidationPage (props) {
    const [queries, setQueries] = useState([])
    const [queryResults, setQueryResults] = useState([])
    const [queryCards, setQueryCards] = useState()
    const [loading, setLoading] = useState(false)
    const [dateType, setDateType] = useState('ds_ship_date');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dateChanged, setDateChange] = useState(false);
    const [showEmailSnapshotModal, setshowEmailSnapshotModal] = useState(false)
    const [endDateEnabled, setEndDateEnabled] = useState(true)

    const { getSession } = useContext(AccountContext);

    function setAllData() {
        clearAllData();
        getSession()
            .then(session => {
                setLoading(true);
                API.get("/shipment_validation_queries", {
                    headers: { "Authorization": session.idToken.jwtToken }
                })
                    .then(res => {
                        setLoading(false);
                        setQueries(addDateToQueries(res.data));
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response !== undefined) {
                            props.showAlert(err.response.data, "error");
                        } else {
                            props.showAlert(err, "error")
                        }
                    })
            })
        setDateChange(false);
    }
    useEffect(() => {
        setAllData();
    }, []);

    function handleSetResults(title, query_results) {
        // Janky as hell, but this takes the results from each <ValidationQuery> object and converts it to text.
        // Passes the text into the <EmailSnapshotModal> component
        const headers = Object.keys(query_results[0])
        var result_string = ""
        result_string += title + "\n"
        if (typeof query_results == "string") {
            result_string += query_results
        } else {
            result_string += headers.join("\t") + "\n"
            var q;
            var h;
            for (q in query_results) {
                for (h in headers) {
                    result_string += query_results[q][headers[h]] + "\t"
                }
                result_string += "\n"
            }
        }
        result_string += "\n"
        setQueryResults(queryResults => [...queryResults, result_string])
    }


    useEffect(() => {
        const cards =  queries.map((query, index) => 
            <ValidationQuery key={index} title={query.title} sql={query.sql} setResultsOnParent={handleSetResults}/>
        )
        setQueryCards(cards)
    }, [queries]);

    function getFormattedDate(date) {
        const d = new Date(date);
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${year}-${month}-${day}`
    }
    function addDateToQueries(queries) {
        const sd = getFormattedDate(startDate)
        const ed = getFormattedDate(endDate)
        var dateString;
        if (endDateEnabled) {
            dateString = `${dateType} >= '${sd}' and ${dateType} <= '${ed}'`   
        } else {
            dateString = `${dateType} >= '${sd}'`   
        }
        for (var i in queries) {
            queries[i].sql = queries[i].sql.replaceAll("<date>", dateString)
        }
        return queries
    }

    function handleSetStartDate(date) {
        if (new Date(endDate) < new Date(date)) {
            props.showAlert("End Date must be after Start Date", "error");
        } else {
            setDateChange(true);
            setStartDate(date)
        }
    }
    function handleSetEndDate(date) {
        if (new Date(date) < new Date(startDate)) {
            props.showAlert("End Date must be after Start Date", "error");
        } else {
            setDateChange(true);
            setEndDate(date)
        }
    }
    function handleSetDateType(dateType) {
        setDateChange(true);
        setDateType(dateType);
    }
    function clearAllData() {
        setQueries([])
        setQueryResults([])
    }
    function handleShowEmailSnapshotModal() {
        setshowEmailSnapshotModal(true);
    }
    function handleCloseEmailSnapshotModal() {
        setshowEmailSnapshotModal(false);
    }
    const handleEndDateChanged = (event) => {
        setEndDateEnabled(event.target.checked)
        setDateChange(true)
    };
    const useStyles = makeStyles((theme) => ({
        refreshButton: {
            marginTop: theme.spacing(3),
        },
    }));
    const classes = useStyles();
    return (
        <>
            <EmailSnapshotModal show={showEmailSnapshotModal} closeEmailSnapshotModal={handleCloseEmailSnapshotModal} body={queryResults.join("\n")}/>
            <Jumbotron>
                <h1>One Way Shipments With Hook Depart Date Within 12 Hours</h1>
                <hr /><br />
                <ReleaseDateWithin12Hours />
            </Jumbotron>
            <Jumbotron>
                <h1>Shipment Validation</h1>
                <hr />
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <CountDownTimer initialMinute="60" onTimeout={setAllData} />
                        </Box>
                        <Col>
                            <Tooltip title="Refresh All Data" aria-label="refresh" placement="left-start" className={`${dateChanged ? "visible" : "invisible"}`}>
                                <Button variant="contained" color="primary" disabled={!dateChanged} onClick={setAllData} className={`${classes.refreshButton} ${dateChanged ? "visible" : "invisible"}`}>
                                    Refresh All
                            </Button>
                            </Tooltip>
                            <Tooltip title="Email Snapshot" aria-label="email" placement="left-start">
                                <Button sx={{ mx: 1 }} variant="contained" color="primary" onClick={handleShowEmailSnapshotModal} className={classes.refreshButton}>
                                    Email Snapshot
                            </Button>
                            </Tooltip>
                        </Col>
                        <Col sm={2}>
                            <DateTypeDropdown label="Date Type" dateType={dateType} setDateType={handleSetDateType} />
                        </Col>
                        <Col sm={2}>
                            <DatePicker label="Start Date (Inclusive)" date={startDate} setDate={handleSetStartDate} />
                        </Col>
                        <Col sm={2}>
                            <DatePicker label="End Date (Inclusive)" date={endDate} setDate={handleSetEndDate} disabled={!endDateEnabled} />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={endDateEnabled}
                                        onChange={handleEndDateChanged}
                                        color="primary"
                                    />
                                }
                                label={endDateEnabled ? "Enabled" : "Disabled"}
                            />
                        </Col>
                    </Box>
                </Container>
                <hr/>
                <br/>
                { loading ? <Loader /> : null}
                <CardColumns>
                    {queryCards}
                </CardColumns>
            </Jumbotron>
        </>
    );
}

export default withSnackbar(ShipmentValidationPage);
