import React, { useEffect, useState, useContext } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import API from '../../../api';
import { AccountContext } from '../../auth/Accounts';
import { withSnackbar } from '../../HOC/Alert'

function EmailSnapshotModal(props) {
    const [formState, setFormState] = useState({});

    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                const evt = { target: { name: "to", value: session.idToken.payload.email } }
                handleChange(evt);
            })
    }, [])

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setFormState((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const handleClose = () => {
        props.closeEmailSnapshotModal();
    }
    const handleShow = () => {
        var date = new Date();
        const subj = { target: { name: "subject", value: "Shipment Validation " + date.toLocaleDateString() + " " + date.toLocaleTimeString() } }
        handleChange(subj);
        const bdy = { target: { name: "body", value: props.body } }
        handleChange(bdy);
    }
    const handleEmailSnapshot = (event) => {
        event.preventDefault();
        var payload=formState;
        getSession()
            .then(session => {
                API.post("/email_snapshot", payload, {
                    headers: { "Authorization": session.idToken.jwtToken }
                })
                    .then(res => {
                        console.log(res.data);
                    })
                    .catch(err => {
                        if (err.response !== undefined) {
                            props.showAlert(err.response.data, "error");
                        } else {
                            props.showAlert(err, "error");
                        }
                    })
            })
        handleClose()
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            onShow={handleShow}
        >
            <Modal.Header>
                <Modal.Title>Email Snapshot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleEmailSnapshot}>                    
                    <TextField className={"mb-2"} value={formState.to} size="small" label="Email" name="to" required fullWidth onChange={handleChange} type="email" />
                    <TextField className={"mb-4"} value={formState.subject} size="small" label="Subject" name="subject" required fullWidth onChange={handleChange} />
                    <TextField className={"mb-2"} value={formState.body} size="small" label="Body" name="body" required fullWidth onChange={handleChange} multiline rows={20} variant="outlined" />
                    <Modal.Footer>
                        <Button variant="contained" color="info" onClick={handleClose} className="mr-3">Close</Button>
                        <Button variant="contained" color="primary" type="submit">Email</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default withSnackbar(EmailSnapshotModal);
