import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import AmountTypeDropdown from '../Selectors/AmountType'
import DivisionDropdown from '../Selectors/Division'


export default function ItemInWrongDivision(props) {

    useEffect(() => {
        const sql = `select distinct de_shipment_id as 'Shipment ID', st_name as Division from DBA.disp_ship left outer join DBA.disp_events on disp_ship.ds_id = disp_events.de_shipment_id left outer join DBA.companies on companies.co_id = de_site left outer join DBA.disp_items on disp_items.di_shipment_id = disp_ship.ds_id left outer join DBA.shipmenttypes on ds_ship_type = shipmenttypes.st_id where de_event_type in ('R', 'N') and terminationlocation = 'T' and de_conf = 'T' and <date> and disp_ship.ds_status not in ('C', 'D') AND disp_items.AmountType in (<amount_type>) AND ds_ship_type not in (<division>)`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        props.handleChange(event);
    };

    return (
        <>
            <Row>
                <Col className="align-self-center">These items are only valid in these divisions. This will alert if they're on a shipment in the wrong division.</Col>
            </Row>
            <Row>
                <Col>
                    <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DivisionDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                </Col>
            </Row>
        </>
    );
}