import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import BillToDropdown from '../Selectors/BillTo'
import DivisionDropdown from '../Selectors/Division'


export default function BillToInWrongDivision(props) {

    useEffect(() => {
        const sql = `SELECT ds_id as 'Shipment ID', st_name as Division, co_name as BillTo FROM DBA.disp_ship LEFT JOIN DBA.companies on companies.co_id = disp_ship.ds_billto_id LEFT JOIN DBA.shipmenttypes on shipmenttypes.st_id = disp_ship.ds_ship_type where <date> AND ds_ship_type in (<division>) AND ds_status not in ('C', 'D') AND disp_ship.ds_billto_id not in (<billto>)`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        props.handleChange(event);
    };

    return (
        <>
            <Row>
                <Col className="align-self-center">These BillTos are ONLY valid in this Division. This will alert if a shipment in this Division has a BillTo not in this list.</Col>
            </Row>
            <Row>
                <Col>
                    <DivisionDropdown handleChange={handleChange} queryParameters={props.queryParameters} required />
                </Col>
            </Row>
            <Row>
                <Col>
                    <BillToDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                </Col>
            </Row>
        </>
    );
}