import React, { useState, useEffect, useContext} from 'react';
import { useRoutes } from 'react-router-dom';
import './App.css';

import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { AccountContext } from './components/auth/Accounts';
import { BlackflyContext } from './components/contexts/Blackfly';

import Container from 'react-bootstrap/Container';
import NavBar from './components/navigation/NavBar'
import Routes from './Routes'
import Header from './components/Header'
import LoginPage from './components/pages/Login/Login'
import SiteAdmin from './components/pages/SiteAdmin/SiteAdmin'

import Slide from "@material-ui/core/Slide";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  
  // const routing = useRoutes(Routes);

  const { getSession, logout } = useContext(AccountContext);
  const { getblackflyapikey } = useContext(BlackflyContext);

  const getLoggedInUser = () => {
    getSession()
      .then(session => { 
        const org = session.idToken.payload.name
        if (org === "super-admin") {
          setSuperAdmin(true)
          setIsLoggedIn(true)
        } else {
          setSuperAdmin(false)
          setIsLoggedIn(true)
          getblackflyapikey(session);
        }
      })
      .catch(err => { setIsLoggedIn(false) })
  }

  useEffect(() => {
    getLoggedInUser()
  }, []);

  // Validate session every 5 minutes. If logged in somewhere else, this will return them to the login page
  useEffect(() => {
    let sessionInterval = setInterval(() => {
      getLoggedInUser()
    }, 300000)
    return () => {
      clearInterval(sessionInterval);
    };
  }, []);

  function handleLogout() {
    logout();
    getLoggedInUser()
  }
  function handleLogin() {
    getLoggedInUser()
  }

  if (isLoggedIn) {
    if (superAdmin) {
      return (
        // <div className="App">
          <SiteAdmin logout={handleLogout} />
        // </div>
      )
    } else {
      return (
        <div className="App">
          <StyledEngineProvider injectLast>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <NavBar logout={handleLogout} />
              <Header />
              <Container id="page_container">
                {/* {routing} */}
                <Routes />
              </Container>
            </LocalizationProvider>
          </StyledEngineProvider>
        </div>
      );
    }
  } else {
    return (
      <div className="App">
        {/* Why does having this here fix it everywhere else */}
        {/* <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          // autoHideDuration={duration}
          open={true}
          TransitionComponent={Slide}
        >
          <MuiAlert variant="filled" elevation={6} severity="success">
            login page
          </MuiAlert>
        </Snackbar> */}
        <LoginPage handleLogin={handleLogin}/>
      </div>
    );
  }
}

export default App;
