import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import Carriers from './pages/Carriers';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Register from './pages/Register';
import Settings from './pages/Settings';

export default function DashboardRoutes(props) {
  return (
    // <Routes>
    //   <Route path="app" element={<DashboardLayout logout={props.logout} />}>
    //     <Route path="account" element={<Account />} />
    //     <Route path="carriers" element={<Carriers />} />
    //     <Route path="dashboard" element={<Dashboard />} />
    //     <Route path="settings" element={<Settings />} />
    //     <Route path="*" element={<Navigate to="/404" />} />
    //   </Route>
    //   <Route path="/" element={<MainLayout logout={props.logout} />}>
    //     <Route path="404" element={<NotFound />} />
    //     <Route path="/" element={<Navigate to="/app/carriers" />} />
    //     <Route path="*" element={<Navigate to="/404" />} />
    //   </Route>
    // </Routes>
    <Routes>
      <Route path="/" element={<DashboardLayout logout={props.logout} />}>
        {/* <Route path="/" element={<Navigate to="/carriers" />} /> */}
        <Route path="carriers" element={<Carriers />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
    </Routes>
  )
}