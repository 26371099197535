import React, { Component } from 'react';

import Image from 'react-bootstrap/Image';

class Logo extends Component {
    render() {
        const logostyle = {
            maxWidth: "500px",
            minWidth: "250px"
        }
        return (
            <Image style={logostyle} src={this.props.src} rounded />
        );
    }
}

export default Logo;