import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function NullShipmentDate(props) {

    useEffect(() => {
        const sql = `SELECT ds_id as 'Shipment ID' FROM "DBA"."disp_ship" WHERE ds_status = 'K' and ds_ship_date is null`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will show shipments without a shipment date specified.</Col>
            </Row>
        </>
    );
}