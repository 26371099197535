import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function ImportExportTerminationMismatch(props) {

    useEffect(() => {
        const sql = `select ds_id as 'Shipment ID', movecode as 'Import/Export' from DBA.disp_ship left join DBA.disp_events left join DBA.companies on disp_events.de_site = companies.co_id where <date> and movecode = 'I' and ds_findest_id != de_site and de_event_type = 'D' and (terminationlocation is null or terminationlocation = 'F') UNION select ds_id as 'Shipment ID', movecode as 'Import/Export' from DBA.disp_ship left join DBA.disp_events left join DBA.companies on disp_events.de_site = companies.co_id where <date> and movecode = 'E' and ds_origin_id != de_site and de_event_type = 'P' and (terminationlocation is null or terminationlocation = 'F')`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will alert if there is an import shipment with a deliver event that isn't at the shipment destination. The inverse is true for export shipments. If the pickup event isn't at the origin, this will alert.</Col>
            </Row>
        </>
    );
}