import React from 'react';
import { Box, Button } from '@material-ui/core';

function CarrierListToolbar(props) {

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={props.showCarrierForm}
          >
            Add carrier
        </Button>
        </Box>
      </Box>
    </>
  )
}

export default CarrierListToolbar;
