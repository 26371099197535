import React, { useState, useEffect, useContext } from 'react';

import { AccountContext } from '../../auth/Accounts';
import API from '../../../api';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Loader from '../../Loading';
import AddQueryModal from './Modals/AddQueryModal';
import { withSnackbar } from "../../HOC/Alert";

import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


function ShipmentValidationTab(props) {
    const [loading, setLoading] = useState(false)
    const [queries, setQueries] = useState([])
    const [queryRows, setQueryRows] = useState([])
    const [queryToEdit, setQueryToEdit] = useState({})
    const [showAddQueryModal, setshowAddQueryModal] = useState(false)

    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        refreshQueries()
    }, []);

    useEffect(() => {
        const query_rows = queries.map((query, index) => 
            <TableRow key={index}>
                <TableCell>{query.status === 0 ? <i>{index + 1}</i> : index + 1}</TableCell>
                <TableCell>{query.status === 0 ? <i>{query.title} <b>(Disabled)</b></i> : query.title}</TableCell>
                <TableCell align="right">
                    <IconButton aria-label="edit" size="small" color="primary" style={{ maxHeight: "50px" }} onClick={() => editQuery(query)}>
                        <EditOutlinedIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
        setQueryRows(query_rows)
    }, [queries]);

    function refreshQueries() {
        getSession()
            .then(session => {
                setLoading(true);
                API.get("/admin/query", {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        setLoading(false);
                        setQueries(res.data);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response !== undefined) {
                            props.showAlert(err.response.data, "error");
                        } else {
                            props.showAlert(err, "error");
                        }
                    })
            })
    }

    function closeModalAndRefresh() {
        setQueryToEdit({}, setshowAddQueryModal(false, setLoading(true, refreshQueries())));
    }

    function handleShowAddQueryModal() {
        setshowAddQueryModal(true)
    }

    function handleCloseAddQueryModal() {
        console.log("Closing modal. Resetting edit query")
        setQueryToEdit({}, setshowAddQueryModal(false))
    }

    function addQuery(formValues, queryParameters) {
        getSession()
            .then(session => {
                const payload = { title: formValues.title,
                                  sql: formValues.sql,
                                  query_type: formValues.query_type,
                                  status: formValues.status,
                                  values: queryParameters}
                API.post("/admin/query", payload, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        closeModalAndRefresh();
                        props.showAlert("Widget Added!")
                    })
                    .catch(err => {
                        console.log(err)
                        props.showAlert("Failed to add widget!", "error");
                    })
            })
        // closeModalAndRefresh()
    }

    function updateQuery(queryID, formValues, queryParameters) {
        getSession()
            .then(session => {
                const payload = {
                    title: formValues.title,
                    sql: formValues.sql,
                    query_type: formValues.query_type,
                    status: formValues.status,
                    values: queryParameters
                }
                API.put("/admin/query/" + queryID, payload, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        closeModalAndRefresh();
                        props.showAlert("Widget Updated!")
                    })
                    .catch(err => {
                        console.log(err)
                        props.showAlert("Failed to update widget!", "error");
                    })
            })
        // closeModalAndRefresh();
    }

    function deleteQuery(queryID) {
        getSession()
            .then(session => {
                API.delete("/admin/query/" + queryID, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        closeModalAndRefresh();
                        props.showAlert("Widget Deleted!")
                    })
                    .catch(err => {
                        console.log(err)
                        props.showAlert("Failed to delete widget!", "error");
                    })
            })
    }

    function editQuery(query) {
        console.log("Setting query to edit")
        setQueryToEdit(query)
        handleShowAddQueryModal()
    }

    const tablestyle = {
        marginBottom: "0px"
    }
    const tableRowStyle = {
        maxHeight: "500px",
        overflow: "auto",
        marginBottom: "1rem",
        textAlign: "left"
    }

    return (
        <Container>
            <AddQueryModal show={showAddQueryModal} closeAddQueryModal={handleCloseAddQueryModal} createQuery={addQuery} query={queryToEdit} updateQuery={updateQuery} deleteQuery={deleteQuery}/>
            <Row style={tableRowStyle}>
                <TableContainer component={Paper}>
                    {loading ? <Loader /> : null}
                    <Table style={tablestyle} size="small" aria-label="user list">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {queryRows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Row>
            <Row className="justify-content-end">
                <Tooltip title="Add Query" aria-label="add" placement="left-start">
                    <Fab color="primary" onClick={handleShowAddQueryModal}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Row>
        </Container>
    );
}

export default withSnackbar(ShipmentValidationTab);
