import React from 'react';
import StaticDropdown from './SelectorTypes/StaticDropdown';


export default function EventTypeDropdown(props) {
    const options = [
        { "name": "HOOK", "value": "H" },
        { "name": "DROP", "value": "R" },
        { "name": "MOUNT", "value": "M" },
        { "name": "DISMOUNT", "value": "N" },
        { "name": "PICKUP", "value": "P" },
        { "name": "DELIVER", "value": "D" }
    ]
    return (<StaticDropdown
        queryParameters={props.queryParameters}
        handleChange={props.handleChange}
        multiple={props.multiple}
        required={props.required}
        disabled={props.disabled}
        displayName={props.displayName === undefined ? "Event Types" : props.displayName}
        name={props.name === undefined ? "event_type" : props.name}
        options={options}
    />)
}
