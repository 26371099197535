import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
}));

export default function DateTypeDropdown(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        props.setDateType(event.target.value);
    };

    const dateTypes = [
        { 'text': 'Shipment Date', 'value': 'ds_ship_date' },
        { 'text': 'Bill Date', 'value': 'ds_bill_date' },
        { 'text': 'Cutoff Date', 'value': 'CutoffDate' },
        { 'text': 'Arrival Date', 'value': 'ArrivalDate' },
        { 'text': 'Last Free Date', 'value': 'LastFreeDate' },
        { 'text': 'Available On Date', 'value': 'AvailableOn' },
        { 'text': 'Available Until Date', 'value': 'AvailableUntil' },
        { 'text': 'Prenote Date', 'value': 'prenotedate' },
        { 'text': 'ETA Date', 'value': 'etadate' },
        { 'text': 'Grounded Date', 'value': 'groundeddate' },
        { 'text': 'Release Date', 'value': 'releasedate' },
        { 'text': 'Pickup By Date', 'value': 'pickupbydate' },
        { 'text': 'Deliver By Date', 'value': 'delbydate' },
        { 'text': 'Empty At Customer Date', 'value': 'emptyatcustomerdate' },
        { 'text': 'Loaded At Customer Date', 'value': 'loadedatcustomerdate' },
        { 'text': 'Rail Billed Date', 'value': 'railbilleddate' }
    ]

    const menuItems = dateTypes.map((item) =>
        <MenuItem value={item.value}>{item.text}</MenuItem>
    );

    return (
        <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <InputLabel id="dropdown-label-id">
                {props.label}
            </InputLabel>
            <Select
                labelId="dropdown-label-id"
                id="dateselector"
                value={props.dateType}
                onChange={handleChange}
                label={props.label}
            >
                {menuItems}
            </Select>
        </FormControl>
    );
}