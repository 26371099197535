import React, {useState, useEffect}from 'react';

const Timer = (props) => {
    const { initialMinute = 0, initialSeconds = 0 } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    props.onTimeout();
                    setMinutes(initialMinute);
                    setSeconds(initialSeconds);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <small className="text-muted">Refreshing in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</small>
    )
}

export default Timer;