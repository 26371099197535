import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, HashRouter } from "react-router-dom";
import { AccountContext } from './components/auth/Accounts';

import Jumbotron from 'react-bootstrap/Jumbotron';
import AdminPage from './components/pages/Admin/Admin'
import ShipmentValidationPage from './components/pages/ShipmentValidation/ShipmentValidation'
import DriverSettlementsPage from './components/pages/DriverSettlements/DriverSettlements'
import DetentionPage from './components/pages/Detention/Detention'
import MissingImagesPage from './components/pages/MissingImages/MissingImages'

function MyRoutes() {
    const [userGroups, setUserGroups] = useState([]);
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                const groups = session.idToken.payload['cognito:groups']
                if (groups) {
                    setUserGroups(groups)
                }
            }).catch(error => { console.log("Routes useEffect", error) });
    }, []);
    const ProtectedElement = ({ component: Component, group: group, ...rest }) => (
        userGroups.includes(group) ?
            <Component {...rest} /> : 
            <Jumbotron><h1>UNAUTHORIZED</h1><hr /><br /></Jumbotron>
    );
    return (
        <Routes>
            <Route path="/"><div></div></Route>
            <Route path="admin"><ProtectedElement group="admin" component={AdminPage} /></Route>
            <Route path="shipment_validation"><ProtectedElement group="shipment-validation" component={ShipmentValidationPage} /></Route>
            <Route path="missing_images"><ProtectedElement group="missing-images" component={MissingImagesPage} /></Route>
            <Route path="*"><h1>404</h1></Route>
        </Routes>
    )
}

// https://github.com/ReactTraining/react-router/blob/f59ee5488bc343cf3c957b7e0cc395ef5eb572d2/docs/advanced-guides/migrating-5-to-6.md#use-useroutes-instead-of-react-router-config
const routes = [
    {
        path: '/',
        element: <h1>Home</h1>
    },
    {
        path: 'admin',
        element: <AdminPage />,
    },
    {
        path: '/shipment_validation',
        element: <ShipmentValidationPage />
    },
    {
        path: '*',
        element: <h1>404</h1>
    },
];
    

export default MyRoutes;