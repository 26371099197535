import React from 'react';
import StaticDropdown from './SelectorTypes/StaticDropdown';


export default function HoursDropdown(props) {
    const options = [
        { "name": "Half Hour", "value": "30" },
        { "name": "One Hour", "value": "60" },
        { "name": "Hour and a Half", "value": "90" },
        { "name": "Two Hours", "value": "120" },
    ]
    return (<StaticDropdown
        queryParameters={props.queryParameters}
        handleChange={props.handleChange}
        multiple={props.multiple}
        required={props.required}
        disabled={props.disabled}
        displayName={props.displayName === undefined ? "Hours" : props.displayName}
        name={props.name === undefined ? "minutes" : props.name}
        options={options}
    />)
}
