import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from '@material-ui/core/Button';

import { AccountContext } from '../../auth/Accounts';
import Loader from '../../Loading';
import API from '../../../api';
import { withSnackbar } from "../../HOC/Alert";

const useStyles = makeStyles((theme) => ({
    form: {
        textAlign: "left",
    },
}));

function LogoTab(props) {
    const [loading, setLoading] = useState(false)
    const [changed, setChanged] = useState(false)
    const [logo, setLogo] = useState("")

    const { getSession } = useContext(AccountContext);

    const classes = useStyles();

    useEffect(() => {
        getSession()
            .then(session => {
                setLoading(true);
                API.get("/org_settings/logo", {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        setLoading(false);
                        setLogo(res.data)
                    })
                    .catch(err => {
                        setLoading(false);
                        props.showAlert(err, "error");
                    })
            })
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        getSession()
            .then(session => {
                setLoading(true);
                API.post("/org_settings/logo", { "logo_path": logo }, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        setLoading(false);
                        setChanged(false);
                        props.showAlert("Logo Set")
                    })
                    .catch(err => {
                        console.log(err.response.data);
                        props.showAlert(err.response.data, "error");
                        setLoading(false);
                    })
            })
    }

    const handleChange = (event) => {
        setChanged(true)
        setLogo(event.target.value);
    }

    return (
        <Container className="mt-3">
            { loading ? <Loader /> :
                <Form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <FormControl fullWidth>
                        <TextField name="logo_path" label="Logo" variant="outlined" helperText="https path to your logo" value={logo} onChange={handleChange} />
                    </FormControl>
                    {changed ?
                        <Row className="justify-content-end">
                            <Button type="submit" variant="contained" color="primary">Save</Button>
                        </Row> : null}
                </Form>
            }
        </Container>
    );
}

export default withSnackbar(LogoTab);
