import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AmountTypeDropdown from '../Selectors/AmountType'
import Hours from '../Selectors/Hours'
import SiteDropdown from '../Selectors/Site'


export default function ItemEventCountMismatch(props) {
    const [minutes, setMinutes] = useState(0);

    useEffect(() => {
        setMinutes(props.queryParameters.minutes)
    }, [])

    useEffect(() => {
        const sql = `select ds_id as 'Shipment ID' from dba.disp_ship left join dba.disp_events where ds_status not in ('C', 'D') and <date> and datediff(minute, (de_arrdate + de_arrtime), (ISNULL(de_arrdate, de_depdate) + de_deptime)) > <minutes> and de_site in (<site>) group by ds_id having (select count(*) as item_count from dba.disp_items where AmountType in (<amount_type>) and di_shipment_id = ds_id group by di_shipment_id) is null ORDER BY ds_id desc`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        if (event.target.name === 'minutes') {
            setMinutes(event.target.value)
        }
        props.handleChange(event);
    };

    return (
        <>
            <Row>
                <Col className="align-self-center">This will show you shipments where there are more than <b>{minutes || 0 }</b> minutes between arrival and departure at specified locations with specified items missing.</Col>
            </Row>
            <Row>
                <Col>
                    <Hours 
                        handleChange={handleChange}
                        queryParameters={props.queryParameters}
                        multiple={false}
                        required
                    />
                </Col>
                <Col>
                    <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SiteDropdown handleChange={handleChange} queryParameters={props.queryParameters} displayName="Sites" multiple required />
                </Col>
            </Row>
        </>
    );
}