import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AmountTypeDropdown from '../Selectors/AmountType'
import BillToDropdown from '../Selectors/BillTo'
import AmountInput from '../Selectors/Amount'


export default function ItemEventCountMismatch(props) {
    const [inverted, setInverted] = useState(0);
    const [billto, setBillTo] = useState();

    useEffect(() => {
        setBillTo(props.queryParameters.billto)
        setInverted(props.queryParameters.inverted)
    }, [])

    useEffect(() => {
        var sql = ""
        if (billto === undefined || billto.length === 0) {
            sql = `select ds_id as 'Shipment ID' from DBA.disp_ship left outer join DBA.disp_items on di_shipment_id = ds_id where <date> and ds_status not in ('C', 'D') and AmountType in (<amount_type>) and di_our_itemamt `+ ( inverted==0 ? '=' : "!=" ) +` <amount>`
        } else {
            sql = `select ds_id as 'Shipment ID' from DBA.disp_ship left outer join DBA.disp_items on di_shipment_id = ds_id where ds_billto_id in (<billto>) and <date> and ds_status not in ('C', 'D') and AmountType in (<amount_type>) and di_our_itemamt ` + ( inverted==0 ? '=' : "!=") + ` <amount>`
        }
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [billto, inverted])

    const handleChange = (event) => {
        if (event.target.name === 'billto') {
            setBillTo(event.target.value)
        }
        props.handleChange(event);
    };

    const handleInverted = ({ target }) => {
        const { name, checked } = target;
        var value;
        if (checked) {
            value = 1
        } else {
            value = 0
        }
        setInverted(value)
        props.handleChangeCheckbox({ target });
    };

    return (
        <>
            <Row>
                {
                    inverted ?
                        <Col className="align-self-center">Look for shipments where these item amounts amounts <b>are not</b> equal to the amount specified.<br />
                Optionally provide a list of BillTos to narrow the search.</Col> :
                        <Col className="align-self-center">Look for shipments where these item amounts amounts <b>are</b> equal to the amount specified.<br />
                Optionally provide a list of BillTos to narrow the search.</Col>
                }
            </Row>
            <Row>
                <Col>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={inverted === 1}
                                onChange={handleInverted}
                                name="inverted"
                                color="primary"
                            />
                        }
                        label="Reverse"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                </Col>
                <Col>
                    <AmountInput handleChange={handleChange} queryParameters={props.queryParameters} required />
                </Col>
            </Row>
            <Row>
                <Col>
                    <BillToDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple />
                </Col>
            </Row>
        </>
    );
}