import React, { useState, useEffect, useContext } from 'react';

import { AccountContext } from '../../auth/Accounts';
import API from '../../../api';

import MissingImageModal from './Modals/MissingImageModal';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Loader from '../../Loading';
import DivisionDropdown from './Selectors/Division'
import ImageTypeDropdown from './Selectors/ImageType'

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function MissingImagesTab() {
    const [missingImageConfigs, setMissingImageConfigs] = useState([])
    const [missingImageToEdit, setMissingImageToEdit] = useState()
    const [showMissingImageModal, setShowMissingImageModal] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(false)

    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        if (refreshData) {
            setRefreshData(false)
        }
        getSession()
            .then(session => {
                setLoading(true);
                API.get("/admin/missing_images", {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        setLoading(false);
                        setMissingImageConfigs(res.data)
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response !== undefined) {
                            alert(err.response.data);
                        } else {
                            alert(err);
                        }
                    })
            })
    }, [refreshData]);

    function editMissingImage(missing_image) {
        setMissingImageToEdit(missing_image)
        setShowMissingImageModal(true)
    }

    function addMissingImage(division, imageType, status) {
        getSession()
            .then(session => {
                setLoading(true);
                const payload = {
                    division: division,
                    image_type: imageType,
                    status: status
                }
                API.post("/admin/missing_images", payload, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.log(err)
                        alert("Failed to add missing image configuration");
                    })
            })
        setShowMissingImageModal(false);
        setMissingImageToEdit({});
        setRefreshData(true);
    }

    function updateMissingImage(id, division, imageType, status) {
        getSession()
            .then(session => {
                setLoading(true);
                const payload = {
                    division: division,
                    image_type: imageType,
                    status: status
                }
                API.put("/admin/missing_images/" + id, payload, {
                    headers: { "Authorization": session.idToken.jwtToken }
                })
                    .then(res => {
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.log(err)
                        alert("Failed to update missing image configuration");
                    })
            })
        setShowMissingImageModal(false);
        setMissingImageToEdit({});
        setRefreshData(true);
    }

    function deleteMissingImage(missingImageID) {
        getSession()
            .then(session => {
                setLoading(true);
                API.delete("/admin/missing_images/" + missingImageID, {
                    headers: { "Authorization": session.idToken.jwtToken }
                })
                    .then(res => {
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.log(err)
                        alert("Failed to delete missing image configuration");
                    })
            })
        setShowMissingImageModal(false);
        setMissingImageToEdit({});
        setRefreshData(true);
    }

    const tablestyle = {
        marginBottom: "0px"
    }
    const tableRowStyle = {
        maxHeight: "500px",
        overflow: "auto",
        marginBottom: "1rem",
        textAlign: "left"
    }

    return (
        <Container>
            <MissingImageModal
            show={showMissingImageModal}
            missingImage={missingImageToEdit}
            closeModal={() => {
                console.log("Closing modal. Resetting edit.")
                setMissingImageToEdit({})
                setShowMissingImageModal(false)
            }}
            createMissingImage={addMissingImage}
            updateMissingImage={updateMissingImage}
            deleteMissingImage={deleteMissingImage} />
            <Row style={tableRowStyle}>
                <TableContainer component={Paper}>
                    {loading ? <Loader /> : null}
                    <Table style={tablestyle} size="small" aria-label="user list">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Divisions</TableCell>
                                <TableCell>Required Images</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { missingImageConfigs.map((missing_image, index) => 
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{missing_image.status === 0 ? "Disabled" : "Enabled" }</TableCell>
                                        <TableCell><DivisionDropdown disabled displayName="" queryParameters={{ "division": missing_image.division }} multiple /></TableCell>
                                        <TableCell><ImageTypeDropdown disabled displayName="" queryParameters={{ "image_types": missing_image.image_type }} multiple /></TableCell>
                                        <TableCell align="right">
                                            <IconButton aria-label="edit" size="small" color="primary" style={{ maxHeight: "50px" }} onClick={() => editMissingImage(missing_image)}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Row>
            <Row className="justify-content-end">
                <Tooltip title="Add Required Image" aria-label="add" placement="left-start">
                    <Fab color="primary" onClick={() => {setShowMissingImageModal(true)}}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Row>
        </Container>
    );
}