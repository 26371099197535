import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../auth/Accounts';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


export default function NavBar (props) {
    const [userGroups, setUserGroups] = useState([]);
    const [username, setUsername] = useState('');
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                const groups = session.idToken.payload['cognito:groups']
                if (groups) {
                    setUserGroups(groups);
                }
                setUsername(session.idToken.payload['cognito:username']);
            }).catch(error => { console.log("NavBar useEffect", error) });
    }, []);
    
    function getNav(group, link, text) {
        if (userGroups.includes(group)) {
            return (
                <Nav.Item>
                    <Nav.Link as={Link} to={link}>{text}</Nav.Link>
                </Nav.Item>
            )
        }
    }
    return (
        <Navbar bg="dark" variant="dark" sticky="top">
            <Navbar.Brand to="/" as={Link}>{username}</Navbar.Brand>
            <Nav className="ml-auto">
                {getNav("admin", "admin", "Admin")}
                {getNav("shipment-validation", "shipment_validation", "Shipment Validation")}
                {getNav("driver-settlements", "driver_settlements", "Settlements")}
                {getNav("detention", "detention", "Detention")}
                {getNav("missing-images", "missing_images", "Missing Images")}
                <Nav.Item>
                    <Nav.Link to="/" as={Link} onClick={props.logout}>Log Out</Nav.Link>
                </Nav.Item>
            </Nav>
        </Navbar>
    )
}
