import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import AmountTypeDropdown from '../Selectors/AmountType'


export default function ItemsCheckedNotOnBobtail(props) {

    useEffect(() => {
        const sql = `select ds_id as 'Shipment ID' from ( select distinct ds_id from DBA.disp_events left join DBA.disp_ship where BobtailOriginEvent is not null and BobtailDestinationEvent is not null and <date> and ds_status not in ('C', 'D') ) s join ( select di_item_id, di_shipment_id, AmountType, di_description, di_pu_event, di_del_event, de_id, BobtailOriginEvent, BobtailDestinationEvent from DBA.disp_items left join DBA.disp_events on di_shipment_id = de_shipment_id and di_pu_event = de_ship_seq where amounttype in (<amount_type>) and BobtailOriginEvent is null and BobtailDestinationEvent is null ) i on i.di_shipment_id = s.ds_id join ( select di_item_id, di_shipment_id, AmountType, di_description, di_pu_event, di_del_event, de_id, BobtailOriginEvent, BobtailDestinationEvent from DBA.disp_items left join DBA.disp_events on di_shipment_id = de_shipment_id and di_del_event = de_ship_seq where amounttype in (<amount_type>) and BobtailOriginEvent is null and BobtailDestinationEvent is null ) e on e.di_shipment_id = s.ds_id order by 'Shipment ID' desc`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        props.handleChange(event);
    };

    return (
        <>
            <Row>
                <Col className="align-self-center">These items should ONLY be assigned to bobtail events. This will alert if they're not.</Col>
            </Row>
            <Row>
                <Col>
                    <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                </Col>
            </Row>
        </>
    );
}