import React, { useState, useContext } from 'react';

import { AccountContext } from '../../../auth/Accounts';
import API from '../../../../api';
import Loader from '../../../Loading';
import { withSnackbar } from '../../../HOC/Alert';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

function AddUserModal(props) {
    const [formState, setFormState] = useState({
        admin: false,
        shipment_validation: false,
        missing_images: false,
        username: "",
        email: ""
    });
    const [loadingGroups, setLoadingGroups] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const { getSession } = useContext(AccountContext);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setFormState((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const handleCheckboxChange = (event, isChecked) => {
        const { name, value } = event.target;
        setFormState((prev) => ({
            ...prev,
            [name]: isChecked
        }));
    };

    const handleClose = () => {
        setFormState({});
        props.closeAddUserModal();
    }

    const handleShow = () => {
        if (props.userToEdit === undefined) {
            setEditMode(false)
        } else {
            setEditMode(true)
            setFormState((prev) => ({
                ...prev,
                ["username"]: props.userToEdit.Username
            }));
            setFormState((prev) => ({
                ...prev,
                ["email"]: props.userToEdit.email
            }));
            var user_groups = []
            getSession()
                .then(session => {
                    setLoadingGroups(true)
                    API.get("admin/users/" + props.userToEdit.Username+"/groups", {
                        headers: { "Authorization": session.idToken.jwtToken }
                    })
                        .then(res => {
                            user_groups = res.data
                            console.log(user_groups)
                            if (user_groups.includes("admin")) {
                                setFormState((prev) => ({
                                    ...prev,
                                    ["admin"]: true
                                }));
                            }
                            if (user_groups.includes("shipment-validation")) {
                                setFormState((prev) => ({
                                    ...prev,
                                    ["shipment_validation"]: true
                                }));
                            }
                            if (user_groups.includes("missing-images")) {
                                setFormState((prev) => ({
                                    ...prev,
                                    ["missing_images"]: true
                                }));
                            }
                            setLoadingGroups(false)
                        })
                        .catch(err => {
                            setLoadingGroups(false)
                            console.log(err);
                            props.showAlert("Failed to retrieve user groups: \n"+err.response.data, "error")
                        })
                })
        }
    }

    const handleCreateUser = (event) => {
        const groups = [];
        if (formState.admin) {
            groups.push("admin")
        }
        if (formState.shipment_validation) {
            groups.push("shipment-validation")
        }
        if (formState.missing_images) {
            groups.push("missing-images")
        }
        props.createUser(formState.username,
            formState.email,
            groups);
    }

    const handleEditUserGroups = (event) => {
        const groups = [];
        if (formState.admin) {
            groups.push("admin")
        }
        if (formState.shipment_validation) {
            groups.push("shipment-validation")
        }
        if (formState.missing_images) {
            groups.push("missing-images")
        }
        props.updateUserGroups(formState.username, groups)
    }

    const handleDeleteUser = (event) => {
        props.deleteUser(props.userToEdit.Username)
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        if (editMode) {
            handleEditUserGroups(event)
        } else {
            handleCreateUser(event)
        }
    }

    return (
        <Modal
            show={props.show}
            onShow={handleShow}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            // animation={false} This fixes a findDOMNode warning, but I like the animation so I'm keeping it 
        >
            <Modal.Header>
                <Modal.Title>{editMode ? "Edit" : "Create"} User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitForm}>
                    <Form.Group controlId="formBasicUsername">
                        <TextField size="small" label="Username" name="username" value={formState.username} variant="outlined" required fullWidth onChange={handleChange} disabled={editMode}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <TextField type="email" size="small" label="Email" name="email" value={formState.email} variant="outlined" required fullWidth onChange={handleChange} disabled={editMode}/>
                    </Form.Group>
                    {loadingGroups ? <Loader /> : 
                        <Form.Group>
                            <FormLabel component="legend">Permissions</FormLabel>
                            <FormControlLabel
                                control={<Checkbox onChange={handleCheckboxChange} name="admin" color="primary" checked={formState.admin} />}
                                label="Admin"
                            />
                            <FormControlLabel
                                control={<Checkbox onChange={handleCheckboxChange} name="shipment_validation" color="primary" checked={formState.shipment_validation}/>}
                                label="Shipment Validation"
                            />
                            <FormControlLabel disabled
                                control={<Checkbox onChange={handleCheckboxChange} name="missing_images" color="primary" checked={formState.missing_images} />}
                                label="Missing Images"
                            />
                        </Form.Group>
                    }
                    <Modal.Footer>
                        {editMode  ?
                            <Button variant="outlined" color="secondary" onClick={handleDeleteUser} className="mr-3">Delete User</Button> :
                            null
                        }
                        <Button variant="contained" color="info" onClick={handleClose} className="mr-3">Close</Button>
                        <Button variant="contained" color="primary" type="submit">{editMode ? "Save Changes" : "Create User"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default withSnackbar(AddUserModal);
