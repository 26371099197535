import React, { useState, useEffect, useContext } from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Loader from '../../Loading';
import { AccountContext } from '../../auth/Accounts';
import { BlackflyContext } from '../../contexts/Blackfly';
import API from '../../../api';
import ImageUploader from './Modals/ImageUploader'

export default function MissingImageTableRow(props) {
    const [loading, setLoading] = useState(false)
    const [showImageUploadModal, setShowImageUploadModal] = useState(false)
    const [imageTypes, setImageTypes] = useState([])
    const [missingImageTypes, setMissingImageTypes] = useState([])
    const [modalMissingImageType, setModalMissingImageType] = useState("")
    const [errorText, setErrorText] = useState("")

    const { getSession } = useContext(AccountContext);
    const { blackflyAPIKey } = useContext(BlackflyContext);

    const refreshImages = () => {
        getSession()
            .then(session => {
                setLoading(true);
                API.get("/imaging/shipment_image_types/" + props.shipment.shipment_id,
                    {
                        headers: { "Authorization": session.idToken.jwtToken },
                        params: { blackflyAPIKey: blackflyAPIKey }
                    }
                )
                    .then(res => {
                        setLoading(false);
                        setImageTypes(res.data)
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response !== undefined) {
                            setErrorText(err.response.data);
                            console.log(err.response.data)
                        } else {
                            setErrorText(err)
                            console.log(err)
                        }
                    })
            })
    }

    useEffect(() => {
        refreshImages()
    }, []);

    useEffect(() => {
        var requiredImages = []
        for (const conf in props.missingImageConfig) {
            if (props.missingImageConfig[conf]["division"].includes(props.shipment.division_id)) {
                requiredImages.push(...props.missingImageConfig[conf]["image_type"])
            }
            // console.log("Required Images: " + requiredImages)
        }
        // console.log("Image Types: " + imageTypes)
        var missingImages = []
        var ri;
        for (ri in requiredImages) {
            var requiredImageObject = props.shipmentImageTypes.find(obj => {
                return obj.image_type_id === requiredImages[ri]
            })
            if (requiredImageObject !== undefined) {
                if (!imageTypes.includes(requiredImageObject.image_type_name)) {
                    missingImages.push(requiredImageObject)
                }   
            }
        }
        setMissingImageTypes(missingImages)
    }, [imageTypes]);

    const uploadImage = (event) => {
        event.preventDefault();
        var formData = new FormData(event.target);
        // for (var value of formData.entries()) {
        //     console.log(value);
        // }
        getSession()
            .then(session => {
                API.post("/imaging/shipment_image/" + props.shipment.shipment_id + "/" + modalMissingImageType, formData,
                    {
                        headers: { "Authorization": session.idToken.jwtToken },
                        params: { blackflyAPIKey: blackflyAPIKey }
                    }
                )
                    .then(res => {
                        console.log(res.data)
                        setShowImageUploadModal(false)
                        refreshImages()
                    })
                    .catch(err => {
                        if (err.response !== undefined) {
                            alert(err.response.data);
                        } else {
                            alert(err)
                        }
                        setShowImageUploadModal(false)
                        refreshImages()
                    })

            })
    }

    return (
        <>
            <ImageUploader show={showImageUploadModal}
                            onHide={() => { setShowImageUploadModal(false)}}
                            tmp={props.shipment.shipment_id}
                            image_type={modalMissingImageType}
                            uploadImage={uploadImage}
                            />
            <TableRow key={props.index}>
                <CopyToClipboard text={props.shipment.shipment_id}>
                    <TableCell>{props.shipment.shipment_id}</TableCell>
                </CopyToClipboard>
                <TableCell>{props.shipment.bill_to_name}</TableCell>
                <TableCell>{props.shipment.container}</TableCell>
                <TableCell>{props.shipment.origin_name}</TableCell>
                <TableCell>{props.shipment.destination_name}</TableCell>
                <TableCell align="center">
                    {loading ? <Loader /> :
                        errorText != "" ?
                            <IconButton aria-label="error">
                                <ErrorIcon />
                            </IconButton>
                            :
                            missingImageTypes.length === 0 ? <CheckIcon /> :
                                <ButtonGroup color="secondary" aria-label="outlined secondary button group">{
                                    missingImageTypes.map((missing_image, index) =>
                                        <Button onClick={() => { setModalMissingImageType(missing_image.image_type_name); setShowImageUploadModal(true) }}>{missing_image.image_type_name}</Button>
                                    )}
                                </ButtonGroup>
                    }
                </TableCell>
            </TableRow>
        </>
    );
}