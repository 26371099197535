import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));


export default function StaticDropdown(props) {
    // if the value has previously been a single selector, the value is stored as just a regular value instead of an array.
    // So changing from single to multiple will break this

    const [value, setValue] = useState([]);
    const [valueList, setValueList] = useState([]);
    const valueOptions = props.options

    const classes = useStyles();

    useEffect(() => {
        if (props.queryParameters[props.name] !== undefined) {
            setValueList(props.queryParameters[props.name])
        }
    }, [])

    useEffect(() => {
        if (props.queryParameters[props.name] !== undefined) {
            setValueList(props.queryParameters[props.name])
            if (props.multiple) {
                const selected = valueOptions.filter(function (obj) {
                    return props.queryParameters[props.name].includes(obj.value);
                });
                setValue(selected)
            } else {
                valueOptions.filter(function (obj) {
                    if (props.queryParameters[props.name] == obj.value) {
                        setValue(obj);
                    }
                });
            }
        }
    }, [valueList])

    // This object stores the "object" thats selected as well as the "ids" that are selected
    // I might be doing a no-no by going from uncontrolled to controlled, but it works....
    const handleChange = (event, value) => {
        // Set on this component and pass it up to parent
        var value_list;
        if (props.multiple) {
            setValue(value)
            value_list = value.map((type) => (type.value))
            setValueList(value_list)
        } else {
            setValue(value)
            if (value == undefined) {
                value_list = undefined
            } else {
                value_list = value.value
            }
            setValueList(value_list)
        }
        const evt = { target: { name: props.name, value: value_list } }
        props.handleChange(evt)
    };

    return (
        <FormControl className={classes.formControl} fullWidth>
            <Autocomplete
                size="small"
                name={props.name}
                multiple={props.multiple}
                disabled={props.disabled}
                options={valueOptions}
                value={value}
                onChange={handleChange}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        required={props.required && (value == undefined || value.length == 0)}
                        label={props.displayName}
                    />
                )}
            />
        </FormControl>
    )
}