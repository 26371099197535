import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import DivisionDropdown from '../Selectors/Division'
import ImageTypeDropdown from '../Selectors/ImageType'


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    }
}));

export default function MissingImageModal(props) {
    const [divisions, setDivisions] = useState([]);
    const [imageTypes, setImageTypes] = useState([]);
    const [status, setStatus] = useState(1);
    const [editMode, setEditMode] = useState(false);

    const classes = useStyles();

    const handleShow = () => {
        if (props.missingImage !== undefined && Object.keys(props.missingImage).length > 0) {
            setEditMode(true)
            setDivisions(props.missingImage.division)
            setImageTypes(props.missingImage.image_type)
            setStatus(props.missingImage.status)
        } else {
            setEditMode(false)
        }
    }

    const resetValues = () => {
        setDivisions([])
        setImageTypes([])
        setStatus(1)
    }

    const handleClose = () => {
        resetValues()
        props.closeModal();
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        if (editMode) {
            props.updateMissingImage(props.missingImage._id.$oid, divisions, imageTypes, status);
        } else {
            props.createMissingImage(divisions, imageTypes, status);
        }
        resetValues()
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            backdrop="static"
            size="lg"
            keyboard={false}
            onShow={handleShow}
        >
            <Modal.Header>
                {editMode ?
                    <Modal.Title>Edit Required Images</Modal.Title> :
                    <Modal.Title>Add Required Images</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitForm}>
                    <Row>
                        <Col className="align-self-center">These Image Types are always required on shipments in this Divisions. If a shipment has all its events confirmed, but is missing a required image, it will show up on the Missing Images page.</Col>
                    </Row>
                    <Row>
                        <Col>
                            <DivisionDropdown handleChange={(event) => { setDivisions(event.target.value) }} queryParameters={{"division": divisions}} multiple required />
                        </Col>
                        <Col>
                            <ImageTypeDropdown handleChange={(event) => { setImageTypes(event.target.value) }} queryParameters={{"image_types": imageTypes}} multiple required />
                        </Col>
                    </Row>
                    <Modal.Footer>
                        {editMode && status === 0 ?
                            <Button variant="contained" color="secondary" className="mr-3"
                                onClick={() => { resetValues();props.deleteMissingImage(props.missingImage._id.$oid);}}>Delete</Button> :
                            null
                        }
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={status === 1}
                                    onChange={({target}) => {
                                        if (target.checked) {
                                            setStatus(1)
                                        } else {
                                            setStatus(0)
                                        }
                                    }}
                                    name="status"
                                    color="primary"
                                />
                            }
                            label="Enabled"
                        />
                        <Button variant="contained" color="info" onClick={handleClose} className="mr-3">Close</Button>
                        <Button variant="contained" color="primary" type="submit">{editMode ? "Save Changes" : "Create Missing Image Requirement"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}