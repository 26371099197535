import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function ShipmentsMissingConfirmedEvents(props) {

    useEffect(() => {
        const sql = `select ds_id as 'Shipment ID' from dba.disp_ship left join( select de_shipment_id, max(de_ship_seq) as max_conf from dba.disp_events where de_conf = 'T' group by de_shipment_id ) conf on conf.de_shipment_id = ds_id left join( select de_shipment_id, max(de_ship_seq) as max_not_conf from dba.disp_events where de_conf = 'F' group by de_shipment_id ) not_conf on not_conf.de_shipment_id = ds_id where <date> and max_conf > max_not_conf order by ds_id`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will show shipments where there are confirmed events after unconfirmed events. Events should be confirmed sequentially.</Col>
            </Row>
        </>
    );
}