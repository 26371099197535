import React from 'react';
import QueryDropdown from './SelectorTypes/QueryDropdown';


export default function ImageTypeDropdown(props) {
    return (<QueryDropdown
        queryParameters={props.queryParameters}
        handleChange={props.handleChange}
        multiple={props.multiple}
        required={props.required}
        disabled={props.disabled}
        displayName={props.displayName === undefined ? "Image Types" : props.displayName}
        name="image_types"
        sql="SELECT it_id as value, type as name FROM DBA.ImageType where topic = 'SHIPMENT' order by type"
    />)
}
