import React, { useEffect, useState, useContext } from 'react';

import Logo from './Logo'

import { AccountContext } from './auth/Accounts';
import API from '../api';
import { withSnackbar } from "./HOC/Alert";

function Header(props) {
    const [logosrc, setLogoSrc] = useState("")

    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                API.get("/org_settings/logo", {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        setLogoSrc(res.data)
                    })
                    .catch(err => {
                        if (err.response !== undefined) {
                            props.showAlert("Failed to retrieve logo:\n" + err.response.data, "error");
                        } else {
                            props.showAlert("Failed to retrieve logo:\n" + err, "error");
                        }
                    })
            })
    });

    return (
        <header className="py-3">
            <Logo src={logosrc} />
        </header>
    )
}

export default withSnackbar(Header);
