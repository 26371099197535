import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function HoursSinceLastConfirmed(props) {

    useEffect(() => {
        const sql = `select ds_id as 'Shipment ID' from ( select ds_id, t1.de_id, t1.de_ship_seq, t1.de_deptime, t1.de_conf, t2.de_id as previous_event_id, Datetime(t2.de_deptime) as previous_event_deptime, DATEDIFF( hour, previous_event_deptime, now() ) as hour_difference from DBA.disp_events t1 join DBA.disp_events t2 on t1.de_shipment_id = t2.de_shipment_id and t1.de_ship_seq = t2.de_ship_seq + 1 left join DBA.disp_ship where ds_status not in ('C', 'D') and t2.de_conf = 'T' and (t1.de_conf = 'F' or t1.de_conf is null) and t1.de_event_type = 'R' and hour_difference >= 6 order by t1.de_shipment_id, t1.de_ship_seq ) a`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will show shipments where it's been 6 hours since the driver last confirmed an event. This will help you determine if a driver has simply forgotten to confirm an event, is delayed, or some other reason.</Col>
            </Row>
        </>
    );
}