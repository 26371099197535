import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function OpenEquipmentLease(props) {

    useEffect(() => {
        // const sql = `select distinct de_shipment_id as 'Shipment ID', st_name as Division from DBA.disp_ship left outer join DBA.disp_events on disp_ship.ds_id = disp_events.de_shipment_id left outer join DBA.companies on companies.co_id = de_site left outer join DBA.disp_items on disp_items.di_shipment_id = disp_ship.ds_id left outer join DBA.shipmenttypes on ds_ship_type = shipmenttypes.st_id where de_event_type in ('R', 'N') and terminationlocation = 'T' and de_conf = 'T' and <date> and disp_ship.ds_status not in ('C', 'D') AND disp_items.AmountType in (<amount_type>) AND ds_ship_type not in (<division>)`
        const sql = `select a.ds_id as 'Shipment ID', equip2_info.eq_ref as Equipment, datediff(day, originationdate, now()) as 'Lease Days'
            from dba.disp_ship a
            left join dba.disp_events on ds_id = de_shipment_id
            left join dba.equip2_shiplinks on equip2_shiplinks.ds_id = a.ds_id
            left join dba.equip2_leaseInfo_EP on equip2_shiplinks.eq_id = DBA.equip2_leaseInfo_EP.oe_id
            left join dba.equip2_info on equip2_info.eq_id = equip2_shiplinks.eq_id
            left join (
            SELECT de_shipment_id, count(de_id) as confirmed_count
            FROM DBA.disp_events
            group by de_shipment_id, de_conf
            having de_conf = 'T'
            ) b on b.de_shipment_id = a.ds_id
            where <date>
            and ds_status not in ('C', 'D')
            and equip2_info.eq_id is not null
            and terminationdate is null and originationdate is not null
            group by a.ds_id, confirmed_count, equip2_info.eq_ref, originationdate
            having count(de_id) = confirmed_count
            UNION ALL 
            select a.ds_id as 'Shipment ID', equip2_info.eq_ref as Equipment, datediff(day, lease_startdate, now()) as 'Lease Days'
            from dba.disp_ship a
            left join dba.disp_events on ds_id = de_shipment_id
            left join dba.equip2_shiplinks on equip2_shiplinks.ds_id = a.ds_id
            left join dba.equip2_leaseInfo_BT on equip2_shiplinks.EQShipLink_ID = equip2_leaseInfo_BT.EQShipLink_ID
            left join dba.equip2_info on equip2_info.eq_id = equip2_shiplinks.eq_id
            left join (
            SELECT de_shipment_id, count(de_id) as confirmed_count
            FROM DBA.disp_events
            group by de_shipment_id, de_conf
            having de_conf = 'T'
            ) b on b.de_shipment_id = a.ds_id
            where <date>
            and ds_status not in ('C', 'D')
            and equip2_info.eq_id is not null
            and lease_enddate is null and lease_startdate is not null
            group by a.ds_id, confirmed_count, equip2_info.eq_ref, lease_startdate
            having count(de_id) = confirmed_count
            order by \`Lease Days\` desc`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        props.handleChange(event);
    };

    return (
        <>
            <Row>
                <Col className="align-self-center">This will find shipments were all the events are confirmed but still has an open equipment lease.</Col>
            </Row>
        </>
    );
}