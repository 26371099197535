import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import CarrierListToolbar from '../components/carrier/CarrierListToolbar'
import AddCarrierForm from '../components/carrier/AddCarrierForm'
import { withSnackbar } from "../../../HOC/Alert";
import API from '../../../../api';
import { AccountContext } from '../../../auth/Accounts';
import Loader from '../../../Loading'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function CarrierList(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [showAddCarrier, setShowAddCarrier] = useState(false);

  const { getSession } = useContext(AccountContext);

  function get_carriers() {
    setLoading(true)
    getSession()
      .then(session => {
        API.get("/super_admin/orgs", {
          headers: { "Authorization": session.idToken.jwtToken }
        })
          .then(res => {
            setLoading(false)
            setData(res.data)
          })
          .catch(err => {
            if (err.response !== undefined) {
              setLoading(false)
              props.showAlert(err.response.data, "error");
            } else {
              setLoading(false)
              props.showAlert(err, "error");
            }
          })
        .catch(err => { console.log(err) })
      })
      .catch(err => { console.log(err) })
  }

  function refreshCarrierList() {
    setData([])
    get_carriers()
  }

  function showMessage(text, type) {
    props.showAlert(text, type)
  }

  useEffect(() => {
    get_carriers();
  }, []);

  return (
    <>
      <Helmet>
        <title>DrayDr | Carriers</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {showAddCarrier ?
            <AddCarrierForm showMessage={showMessage} hideCarrierForm={() => { setShowAddCarrier(false); refreshCarrierList(); }}/> :
            <>
              <CarrierListToolbar showCarrierForm={() => { setShowAddCarrier(true) }} />
              <Box sx={{ pt: 3 }}>
                {loading ? <Loader /> : null}
                <MaterialTable
                  icons={tableIcons}
                  options={{
                    draggable: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 30, 60, 100],
                    thirdSortClick: false
                  }}
                  columns={[
                    {
                      title: "Carrier Name",
                      field: "org_name"
                    }
                  ]}
                  data={data}
                  title="Carriers"
                />
              </Box>
            </>
          }
        </Container>
      </Box>
    </>
  )
}

export default withSnackbar(CarrierList)