import React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core'
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@material-ui/core';
import {LogOut as LogOutIcon } from 'react-feather';
import MenuIcon from '@material-ui/icons/Menu';

const DashboardNavbar = ({ onMobileNavOpen, logout, ...rest }) => {
  const [notifications] = useState([]);

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <Link to="/" color="inherit" component={RouterLink}>
            <IconButton
              color="inherit"
              onClick={logout}
            >
              <LogOutIcon />
            Logout
            </IconButton>
          </Link>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
            <Link to="/" color="inherit" component={RouterLink}>
              <IconButton
                color="inherit"
                onClick={logout}
              >
                <LogOutIcon />
              </IconButton>
            </Link>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
