import React from 'react';
import QueryDropdown from './SelectorTypes/QueryDropdown';


export default function AmountTypeDropdown(props) {

    return (<QueryDropdown 
        queryParameters={props.queryParameters}
        handleChange={props.handleChange}
        multiple={props.multiple}
        required={props.required}
        disabled={props.disabled}
        displayName={props.displayName === undefined ? "Amount Types" : props.displayName}
        name="amount_type"
        sql="SELECT id as value, name FROM DBA.AmountType ORDER BY name ASC"
        />)
}