import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';



export default function EventTimesOverlap(props) {

    useEffect(() => {
        const sql = `select ds_id as 'Shipment ID',
        ISNULL(t1.de_arrdate, t1.de_depdate) + t1.de_deptime as 'Event 1 Departure',
        t2.de_arrdate + t2.de_arrtime as 'Event 2 Arrival'
        from dba.disp_ship
        join dba.disp_events t1 on ds_id = t1.de_shipment_id
        join dba.disp_events t2 on t1.de_shipment_id = t2.de_shipment_id and t1.de_ship_seq = t2.de_ship_seq - 1
        where <date>
        AND ISNULL(t1.de_arrdate, t1.de_depdate) + t1.de_deptime is not NULL
        AND ISNULL(t1.de_arrdate, t1.de_depdate) + t1.de_deptime >= t2.de_arrdate + t2.de_arrtime
        order by ds_id, t1.de_ship_seq`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will look for events where the first event departure is equal to or before the arrival of the next event.</Col>
            </Row>
        </>
    );
}