import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Loader from '../../../Loading';


export default function UploadImageModal(props) {
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            show={props.show}
            backdrop="static"
            keyboard={false}
            onShow={() => {setLoading(false)}}
        >
            <Modal.Header>
                <Modal.Title>Image Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(event) => { setLoading(true); props.uploadImage(event)}}>
                    <Form.Group controlId="formBasicTMP">
                        <TextField type="number" size="small" label="Shipment ID" name="shipment_id" value={props.tmp} variant="outlined" required fullWidth disabled />
                    </Form.Group>
                    <Form.Group controlId="formBasicImageType">
                        <TextField size="small" label="Image Type" name="image_type" value={props.image_type} variant="outlined" required fullWidth disabled />
                    </Form.Group>
                    <Form.Group controlId="formBasicImageFile">
                        <input id="image_file" name="image_file" type="file" accept="image/*,application/pdf" class="form-control-file" required disabled={loading}></input>
                    </Form.Group>
                    <Modal.Footer>
                        { loading ? <Loader /> : 
                        <>
                            <Button variant="contained" color="secondary" onClick={props.onHide} className="mr-3">Close</Button>
                            <Button variant="contained" color="primary" type="submit">Upload Image</Button>
                        </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}