import React, { useState, useEffect, useContext }from 'react';

import { AccountContext } from '../../auth/Accounts';
import API from '../../../api';

import AddUserModal from './Modals/AddUserModal';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Loader from '../../Loading';
import { withSnackbar } from "../../HOC/Alert";

import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function UserManagementTab(props) {
    const [userInfo, setUserInfo] = useState([])
    const [userToEdit, setUserToEdit] = useState()
    const [showAddUserModal, setshowAddUserModal] = useState(false)
    const [refreshUserData, setRefreshUserData] = useState(false)
    const [loading, setLoading] = useState(false)

    const { getSession } = useContext(AccountContext);

    // useEffect(() => {
    //     // alert("HEY")
    //     props.showAlert("Hey")
    // }, []);

    useEffect(() => {
        if (refreshUserData) {
            setRefreshUserData(false)
        }
        getSession()
            .then(session => {
                setLoading(true);
                API.get("/admin/get_org_users", {
                    headers: { "Authorization": session.idToken.jwtToken},
                    params: { org_name: session.idToken.payload.name }
                })
                .then(res => {
                    const userData = res.data.map((user, index) =>
                        <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{user.Username}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.given_name}</TableCell>
                            <TableCell>{user.family_name}</TableCell>
                            <TableCell align="right">
                                <IconButton aria-label="edit" size="small" color="primary" style={{ maxHeight: "50px" }} onClick={() => setUserToUpdate(user)}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                    setLoading(false);
                    setUserInfo(userData);
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response !== undefined) {
                        props.showAlert(err.response.data, "error");
                    } else {
                        props.showAlert(err, "error");
                    }
                })
        })
    }, [refreshUserData]);

    function addUser(username, email, groups) {
        const payload = {
            username: username,
            email: email,
            groups: groups
        }
        getSession()
            .then(session => {
                API.post("admin/add_user", payload, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                .then(res => {
                    console.log(res.data);
                    setshowAddUserModal(false);
                    setRefreshUserData(true);
                    props.showAlert("Invitation email sent to " + email, "success", 5000)
                })
                .catch(err => {
                    props.showAlert(err.response.data, "error");
                })
            })
    }

    function deleteUser(username) {
        getSession()
            .then(session => {
                API.delete("admin/users/" + username, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        console.log(res.data);
                        setshowAddUserModal(false);
                        setRefreshUserData(true);
                        props.showAlert("Deleted user: " + username)
                    })
                    .catch(err => {
                        props.showAlert("Failed to delete user:\n" + err.response.data, "error");
                    })
            })
    }

    function updateUserGroups(username, groups) {
        getSession()
            .then(session => {
                setLoading(true);
                const payload = {
                    groups: groups,
                }
                API.put("/admin/users/" + username + "/groups", payload, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { org_name: session.idToken.payload.name }
                })
                    .then(res => {
                        setLoading(false);
                        props.showAlert("Changes Saved!")
                    })
                    .catch(err => {
                        setLoading(false);
                        console.log(err);
                        props.showAlert("Failed to edit user", "error");
                    })
            })
        handleCloseAddUserModal()
        setRefreshUserData(true);
    }

    function setUserToUpdate(user) {
        setUserToEdit(user)
        handleShowAddUserModal()
    }

    function handleShowAddUserModal() {
        setshowAddUserModal(true)
    }

    function handleCloseAddUserModal() {
        console.log("Closing modal. Resetting user to edit")
        setUserToEdit(undefined)
        setshowAddUserModal(false)
    }

    const tablestyle = {
        marginBottom: "0px"
    }
    const tableRowStyle = {
        maxHeight: "500px",
        overflow: "auto",
        marginBottom: "1rem",
        textAlign: "left"
    }

    return (
        <Container>
            <AddUserModal show={showAddUserModal} closeAddUserModal={handleCloseAddUserModal} createUser={addUser} deleteUser={deleteUser} updateUserGroups={updateUserGroups} userToEdit={userToEdit}/>
            <Row style={tableRowStyle}>
                <TableContainer component={Paper}>
                    {loading ? <Loader /> : null}
                    <Table style={tablestyle} size="small" aria-label="user list">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userInfo}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Row>
            <Row className="justify-content-end">
                <Tooltip title="Add User" aria-label="add" placement="left-start">
                    <Fab color="primary" onClick={handleShowAddUserModal}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Row>
        </Container>
    );
}

export default withSnackbar(UserManagementTab);
