import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function DriverRoutedToCancelledShipment(props) {

    useEffect(() => {
        const sql = `SELECT distinct ds_id as 'Shipment ID' FROM DBA.disp_ship left join DBA.disp_events left join DBA.disp_items where <date> and de_driver is not null and di_our_itemamt > 0 and ds_status = 'C' group by ds_id having count(di_item_id) > 0`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will look for cancelled shipments that have charges where a driver is routed to any event.</Col>
            </Row>
        </>
    );
}