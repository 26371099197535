import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import AmountTypeDropdown from '../Selectors/AmountType'

export default function AmountTypeQuery(props) {
    
    useEffect(() => {
        const sql = `SELECT di_shipment_id as 'Shipment ID' FROM "DBA"."disp_items" left outer join "DBA"."disp_ship" on disp_ship.ds_id = di_shipment_id where AmountType in (<amount_type>) and <date> and ds_status not in ('C', 'D')`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        props.handleChange(event);
    };
    
    return (
            <div>
                <Row>
                    <Col className="align-self-center">Look for shipments that have items with these Amount Types...</Col>
                </Row>
                <Row>
                    <Col>
                    <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                    </Col>
                </Row>
            </div>
    );
}