import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

export default function DollarInput(props) {
    const [value, setValue] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        if (props.queryParameters.amount !== undefined) {
            setValue(props.queryParameters.amount)
        }
    }, [])

    const handleChange = (event) => {
        setValue(event.target.value)
        props.handleChange(event);
    };

    return (
        <FormControl className={classes.formControl} fullWidth>
            <CurrencyTextField
                label={props.displayName === undefined ? "Amount" : props.displayName}
                variant="standard"
                size="small"
                value={value}
                name="amount"
                required={props.required}
                disabled={props.disabled}
                currencySymbol="$"
                outputFormat="string"
                onChange={handleChange}
            />
        </FormControl>
    )
}
