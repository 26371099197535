import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function DaysSinceDriversLastRoutedMove(props) {

    useEffect(() => {
        const sql = `select em_fn + ' ' + em_ln + ' ('+ em_ref + ')' as Driver, last_routed_date as 'Last Routed Date', day_diff as 'Days From Today' from( select de_driver, max(ISNULL(de_arrdate, de_apptdate)) as last_routed_date, datediff(day, last_routed_date, now()) as day_diff FROM DBA.disp_events JOIN DBA.disp_ship on DBA.disp_ship.ds_id = DBA.disp_events.de_shipment_id group by de_driver) a JOIN DBA.employees on DBA.employees.em_id = a.de_driver where a.day_diff > 0 and em_status = 'K'`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will show active drivers where their last routed move isn't today.</Col>
            </Row>
        </>
    );
}