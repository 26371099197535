import React, { createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from './UserPool';

const AccountContext = createContext();

const Account = props => {
    const getSession = async () =>
        await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        reject();
                    } else {
                        resolve(session);
                    }
                });
            } else {
                reject();
            }
        });

    const authenticate = (Username, Password) =>
        new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });
            const authDetails = new AuthenticationDetails({ Username, Password });
            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    console.log("Clearing out all other sessions")
                    user.globalSignOut();
                    user.authenticateUser(authDetails, {
                        onSuccess: data => {
                            console.log('Login Success:', data);
                            resolve({ data: data, new_password_required: false });
                        }
                    })
                },
                onFailure: err => {
                    console.error('Login Failure:', err);
                    reject(err);
                },
                newPasswordRequired: data => {
                    console.log('New Password Required:', data);
                    resolve({ data: data, new_password_required: true, user_session: user });
                }
            });
        });

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
            console.log("Signed out.");
        }
    }


    return (
        <AccountContext.Provider value={{
            authenticate,
            getSession,
            logout
        }}>
            {props.children}
        </AccountContext.Provider>
    );
};

export { Account, AccountContext };