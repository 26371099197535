import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function NonRouteableEventsOnShipment(props) {

    useEffect(() => {
        const sql = `select de_shipment_id as 'Shipment ID' from DBA.disp_events left join DBA.disp_ship on de_shipment_id = ds_id where <date> and Routable = 'F' and ds_status not in ('C', 'D') order by de_shipment_id desc`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will look for Non-Routable events on a shipment.</Col>
            </Row>
        </>
    );
}