import React, { useEffect, useContext, useState } from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DatePicker from '../../DatePicker'
import DateTypeDropdown from '../../DateTypeDropdown'

import API from '../../../api';
import { AccountContext } from '../../auth/Accounts';
import { BlackflyContext } from '../../contexts/Blackfly';
import Loader from '../../Loading';
import MissingImageTableRow from './MissingImageTableRow'

export default function MissingImagesPage() {
    const [completedShipments, setCompletedShipments] = useState([]);
    const [missingImageConfig, setMissingImageConfig] = useState([]);
    const [shipmentImageTypes, setShipmentImageTypes] = useState([]);
    const [loading, setLoading] = useState(true)
    const [dateType, setDateType] = useState('ds_ship_date');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [endDateEnabled, setEndDateEnabled] = useState(true);

    const { getSession } = useContext(AccountContext);
    const { blackflyAPIKey } = useContext(BlackflyContext);

    function getCompletedShipments() {
        getSession()
            .then(session => {
                setLoading(true);
                var payload = {
                    blackflyAPIKey: blackflyAPIKey,
                    date_type: dateType,
                    start_date: getFormattedDate(startDate)
                }
                if (endDateEnabled) {
                    payload["end_date"] = getFormattedDate(endDate)
                }
                API.get("/queries/completed_shipments",
                    {
                        headers: { "Authorization": session.idToken.jwtToken},
                        params: payload
                    }
                )
                    .then(res => {
                        setLoading(false);
                        setCompletedShipments(res.data)
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response !== undefined) {
                            alert(err.response.data);
                        } else {
                            alert(err)
                        }
                    })
            })
    }

    function getMissingImageConfig() {
        getSession()
            .then(session => {
                API.get("/admin/missing_images",
                    {
                        headers: { "Authorization": session.idToken.jwtToken },
                        params: { org_name: session.idToken.payload.name }
                    }
                )
                    .then(res => {
                        setMissingImageConfig(res.data)
                    })
                    .catch(err => {
                        if (err.response !== undefined) {
                            alert(err.response.data);
                        } else {
                            alert(err)
                        }
                    })
            })
    }
    function getImageTypes() {
        getSession()
            .then(session => {
                var payload = {
                    blackflyAPIKey: blackflyAPIKey
                }
                API.get("/queries/image_types",
                    {
                        headers: { "Authorization": session.idToken.jwtToken},
                        params: payload
                    }
                )
                    .then(res => {
                        setShipmentImageTypes(res.data)
                    })
                    .catch(err => {
                        if (err.response !== undefined) {
                            alert(err.response.data);
                        } else {
                            alert(err)
                        }
                    })
            })
    }
    
    function refreshAll() {
        if (blackflyAPIKey) {
            getMissingImageConfig()
            getCompletedShipments()
            getImageTypes()
        }
    }

    function clearAllData() {
        setCompletedShipments([])
    }

    useEffect(() => {
        refreshAll();
    }, []);

    function handleSetDateType(dateType) {
        setDateType(dateType);
    }

    function handleSetStartDate(date) {
        if (new Date(endDate) < new Date(date)) {
            alert("End Date must be after Start Date");
        } else {
            setStartDate(date)
        }
    }

    function handleSetEndDate(date) {
        if (new Date(date) < new Date(startDate)) {
            alert("End Date must be after Start Date");
        } else {
            setEndDate(date)
        }
    }

    const handleEndDateChanged = (event) => {
        setEndDateEnabled(event.target.checked)
    };

    function getFormattedDate(date) {
        const d = new Date(date);
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${year}-${month}-${day}`
    }

    const tablestyle = {
        marginBottom: "0px"
    }
    const tableRowStyle = {
        maxHeight: "500px",
        overflow: "auto",
        marginBottom: "1rem",
        textAlign: "left"
    }

    const useStyles = makeStyles((theme) => ({
        refreshButton: {
            marginTop: theme.spacing(3),
        },
    }));
    const classes = useStyles();
    return (
        <Jumbotron>
            <h1>Missing Images</h1>
            <hr /><br />
            <Row>
                <Col sm={4}></Col>
                <Col>
                    <Tooltip title="Refresh All Data" aria-label="refresh" placement="left-start">
                        <Button variant="contained" color="primary" onClick={() => {clearAllData();refreshAll();}} className={classes.refreshButton}>
                            Refresh All
                            </Button>
                    </Tooltip>
                </Col>
                <Col>
                    <DateTypeDropdown label="Date Type" dateType={dateType} setDateType={handleSetDateType} />
                </Col>
                <Col>
                    <DatePicker label="Start Date (Inclusive)" date={startDate} setDate={handleSetStartDate} />
                </Col>
                <Col>
                    <DatePicker label="End Date (Inclusive)" date={endDate} setDate={handleSetEndDate} disabled={!endDateEnabled} />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={endDateEnabled}
                                onChange={handleEndDateChanged}
                                color="primary"
                            />
                        }
                        label={endDateEnabled ? "Enabled" : "Disabled"}
                    />
                </Col>
            </Row>
            <hr />
            <Row style={tableRowStyle}>
                {!blackflyAPIKey ? <h1>Blackfly API Key Not Set</h1> : 
                    <TableContainer component={Paper}>
                        {loading ? <Loader /> : null}
                        <Table style={tablestyle} size="small" aria-label="user list">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Shipment ID</TableCell>
                                    <TableCell>Bill To</TableCell>
                                    <TableCell>Container</TableCell>
                                    <TableCell>Origin</TableCell>
                                    <TableCell>Destination</TableCell>
                                    <TableCell>Missing Images</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {completedShipments.map((shipment, index) =>
                                    <MissingImageTableRow shipment={shipment} index={index}
                                        missingImageConfig={missingImageConfig}
                                        shipmentImageTypes={shipmentImageTypes}
                                    />
                                )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Row>
        </Jumbotron>
    );
}