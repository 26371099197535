import React from 'react';
import QueryDropdown from './SelectorTypes/QueryDropdown';


export default function DivisionDropdown(props) {
    return (<QueryDropdown
        queryParameters={props.queryParameters}
        handleChange={props.handleChange}
        multiple={props.multiple}
        required={props.required}
        disabled={props.disabled}
        displayName={props.displayName === undefined ? "Divisions" : props.displayName}
        name="division"
        sql="SELECT st_id as value, st_name as name FROM DBA.shipmenttypes where st_status = 'K' order by st_name ASC"
    />)
}
