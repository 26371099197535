import React, { useState, useContext, createContext } from 'react';

import API from '../../api';


const BlackflyContext = createContext("");

const BlackflyAPIKey = props => {
    const [blackflyAPIKey, setBlackflyAPIKey] = useState("");

    const getblackflyapikey = (session) => {
        API.get("/org_settings/blackfly",
            {
                headers: { "Authorization": session.idToken.jwtToken },
                params: { org_name: session.idToken.payload.name }
            }
        )
            .then(res => {
                console.log("Got Blackfly API Key: " + res.data)
                setBlackflyAPIKey(res.data)
            })
            .catch(err => {
                if (err.response !== undefined) {
                    alert(err.response.data);
                } else {
                    alert(err)
                }
            })
    }

    return (
        <BlackflyContext.Provider value={{
            blackflyAPIKey,
            getblackflyapikey
        }}>
            {props.children}
        </BlackflyContext.Provider>
    )
}

export { BlackflyAPIKey, BlackflyContext };
