import React from 'react';
import QueryDropdown from './SelectorTypes/QueryDropdown';


export default function BillToDropdown(props) {
    return (<QueryDropdown
        queryParameters={props.queryParameters}
        handleChange={props.handleChange}
        multiple={props.multiple}
        required={props.required}
        disabled={props.disabled}
        displayName={props.displayName === undefined ? "Bill Tos" : props.displayName}
        name="billto"
        sql="SELECT co_id as value, co_name as name FROM DBA.companies where co_status = 'K' and co_allow_billing = 'T' order by name ASC"
    />)
}
