import React, { useState, useEffect, useContext } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


export default function CustomQuery(props) {
    const [sqlString, setSQLString] = useState("");

    useEffect(() => {
        if (props.sql !== undefined) {
            setSQLString(props.sql)
        }
    }, [sqlString])

    const handleSQLChange = (event) => {
        // Set on this component and pass it up to parent
        console.log(event.target.value);
        setSQLString(event.target.value);
        props.handleChange(event);
    };

    return (
            <div>
                <Row>
                    {/* <Col className="align-self-center"><p><b>WARNING:</b>This SQL will be exectuted every time you render the shipment validation page. Large queries are not recommended for performance reasons. If you want the date selection logic to work, you need to include &lt;date&gt; in the where clause.</p>
                        <p>Ex: Select ds_id as 'Shipment ID' from DBA.disp_ship where &lt;date&gt;</p>
                    </Col> */}
                <Col className="align-self-center"><p><b>WARNING:</b>This SQL will be exectuted every time you render the shipment validation page. Large queries are not recommended for performance reasons.</p>
                </Col>
                </Row>
                <Row>
                    <Col>
                        <FormControl id="sql" fullWidth>
                            <TextField
                                label="SQL"
                                // value={sqlString}
                                value="Not implemented"
                                name="sql"
                                multiline
                                rows={20}
                                onChange={handleSQLChange}
                                variant="outlined"
                                disabled
                            />
                        </FormControl>
                    </Col>
                </Row>
            </div>
    );
}