import React, { useState, useEffect, useContext } from 'react';

import API from '../../../api';
import { AccountContext } from '../../auth/Accounts';
import { BlackflyContext } from '../../contexts/Blackfly';
import Loader from '../../Loading';
import Row from 'react-bootstrap/Row';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import CountDownTimer from '../../CountDownTimer';


export default function RelaseWithin12Hours() {
    const [queryResults, setQueryResults] = useState([])
    const [resultRows, setResultRows] = useState([])
    const [resultHeaders, setResultHeaders] = useState([])
    const [loading, setLoading] = useState(true)
    const [errorText, setErrorText] = useState("")

    const { getSession } = useContext(AccountContext);
    const { blackflyAPIKey } = useContext(BlackflyContext);

    function execute_sql() {
        getSession()
            .then(session => {
                setLoading(true);
                clearData();
                const sql = "SELECT co_name as Company, list(distinct ds_ref1_text) as Containers from dba.disp_ship left join DBA.disp_events left join dba.companies on companies.co_id = ds_origin_id left join ( SELECT de_shipment_id, count(de_id) as confirmed_event_count FROM DBA.disp_events group by de_shipment_id, de_conf having de_conf = 'T') ce_count on ce_count.de_shipment_id = ds_id left join ( SELECT de_shipment_id, count(de_id) as event_count FROM DBA.disp_events group by de_shipment_id) e_count on e_count.de_shipment_id = ds_id where datediff(hour, (ISNULL(de_arrdate, de_depdate) + de_deptime), now()) <= 12 and movecode='O' and confirmed_event_count = event_count and de_site = ds_origin_id group by co_name"
                const payload = { sql: sql }
                API.post("/execute_sql", payload, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { blackflyAPIKey: blackflyAPIKey }
                })
                    .then(res => {
                        setLoading(false);
                        setQueryResults(res.data);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response !== undefined) {
                            console.log(err.response.data);
                            setErrorText(err.response.data)
                        } else {
                            setErrorText("Unknown Error")
                        }
                    })
            })
    }

    function clearData() {
        setQueryResults([]);
        setErrorText("");
    }

    useEffect(() => {
        execute_sql();
    }, []);

    useEffect(() => {
        if (queryResults === undefined || queryResults.length === 0) {
            setResultHeaders([])
        } else {
            const keys = Object.keys(queryResults[0])
            setResultHeaders(keys);
        }
    }, [queryResults]);

    useEffect(() => {
        const rows = queryResults.map((result_row, index) =>
            <TableRow key={index}>{getRowData(result_row)}</TableRow>
        );
        setResultRows(rows)
    }, [resultHeaders]);

    function getRowData(result_row) {
        return resultHeaders.map((header, index) => {
            return (<TableCell key={index}>{result_row[header]}</TableCell>)
        })
    }
    function getHeaderRow() {
        return resultHeaders.map((header, index) => {
            return (<TableCell key={index}>{header}</TableCell>)
        })
    }


    return (
        <Row>
            { loading?<Loader /> : null}
            { errorText ? <div>{errorText}</div> : null}
            <CountDownTimer initialMinute="60" onTimeout={execute_sql} />
            <TableContainer component={Paper}>
                <Table size="small" aria-label="query results">
                    <TableHead>
                        <TableRow>
                            {getHeaderRow()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resultRows}
                    </TableBody>
                </Table>
            </TableContainer>
        </Row>
    );
}