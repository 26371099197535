import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormHelperText,
    Link,
    TextField,
    Typography
} from '@material-ui/core';

import { AccountContext } from '../../../../auth/Accounts';
import API from '../../../../../api';
import Loader from '../../../../Loading'


export default function AddCarrierForm(props) {
    const { getSession } = useContext(AccountContext);

    function addCarrier(values, callback) {
        const payload = {
            org_name: values["carrierName"]
        }
        getSession()
            .then(session => {
                API.post("/super_admin/orgs", payload, {
                    headers: { "Authorization": session.idToken.jwtToken }
                })
                    .then(res => {
                        const payload = {
                            username: values["userName"],
                            email: values["email"],
                            groups: ["admin"]
                        }
                        API.post("/admin/add_user", payload, {
                            headers: { "Authorization": session.idToken.jwtToken },
                            params: { org_name: values["carrierName"]}
                        })
                        .then(res=> {
                            props.showMessage("Carrier Added! Check the users email to get started", "success")
                            callback();
                        })
                        .catch(err => {
                            if (err.response !== undefined) {
                                props.showMessage("Created carrier but failed to add user. " + err.response.data, "error")
                            } else {
                                props.showMessage("Created carrier but failed to add user. " + err, "error")
                            }
                            callback();
                        })
                    })
                    .catch(err => {
                        if (err.response !== undefined) {
                            props.showMessage("Failed to add the carrier. " + err.response.data, "error")
                        } else {
                            props.showMessage("Failed to add the carrier. " + err, "error")
                        }
                        callback();
                    })
            })
    }

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
        >
            <Container maxWidth="sm">
                <Formik
                    initialValues={{
                        userName: '',
                        email: '',
                        carrierName: '',
                    }}
                    validationSchema={
                        Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            userName: Yup.string().max(255).required('Username is required'),
                            carrierName: Yup.string().max(255).required('Carrier name is required')
                        })
                    }
                    onSubmit={(values, actions) => {
                        addCarrier(values, () => {
                            actions.setSubmitting(false);
                            actions.resetForm();
                            props.hideCarrierForm()
                        });
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ mb: 2 }}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Add new carrier
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="body2"
                                >
                                    This will create the Carrier in DrayDr and get the first Admin user added
                                </Typography>
                            </Box>
                            <TextField
                                error={Boolean(touched.userName && errors.userName)}
                                fullWidth
                                helperText={touched.userName && errors.userName}
                                label="Username"
                                margin="normal"
                                name="userName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.userName}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Email Address"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.carrierName && errors.carrierName)}
                                fullWidth
                                helperText={touched.carrierName && errors.carrierName}
                                label="Carrier name"
                                margin="normal"
                                name="carrierName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.carrierName}
                                variant="outlined"
                            />
                            {isSubmitting ? <Loader /> : 
                            <>
                                <Box sx={{ py: 2 }}>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Add Carrier
                                    </Button>
                                </Box>
                                <Box sx={{ py: 0 }}>
                                    <Button
                                        color="secondary"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        onClick={props.hideCarrierForm}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </>
                            }
                        </form>
                    )}
                </Formik>
            </Container>
        </Box>
    );
}
