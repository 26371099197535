import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

export default function MyDatePicker(props) {

    const handleDateChange = (date) => {
        props.setDate(date);
    };

    return (
        <DatePicker
            disableToolbar
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            autoOk={true}
            label={props.label}
            value={props.date}
            renderInput={props => <TextField variant="outlined" {...props} />}
            disabled={props.disabled}
            onChange={handleDateChange}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
        />
    );
}
