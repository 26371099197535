import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Loader from '../../../Loading';
import AmountTypeQuery from '../QueryTypes/AmountTypeQuery';
import BillToInWrongDivision from '../QueryTypes/BillToInWrongDivision';
import ConfirmedEventsAtSite from '../QueryTypes/ConfirmedEventsAtSite';
import CustomQuery from '../QueryTypes/CustomQuery';
import DaysSinceDriversLastRoutedMove from '../QueryTypes/DaysSinceDriversLastRoutedMove';
import DeliverPickupAtSameLocation from '../QueryTypes/DeliverPickupAtSameLocation';
import DriverRoutedToCancelledShipment from '../QueryTypes/DriverRoutedToCancelledShipment';
import EventTimesOverlap from '../QueryTypes/EventTimesOverlap';
import ExportFirstDropNotDestination from '../QueryTypes/ExportFirstDropNotDestination';
import HoursSinceLastConfirmed from '../QueryTypes/HoursSinceLastConfirmed';
import ImportExportTerminationEvent from '../QueryTypes/ImportExportTerminationEvent';
import ItemAmounts from '../QueryTypes/ItemAmounts';
import ItemEventCountMismatch from '../QueryTypes/ItemEventCountMismatch';
import ItemInWrongDivision from '../QueryTypes/ItemInWrongDivision';
import ItemsAtOriginOrDestination from '../QueryTypes/ItemsAtOriginOrDestination';
import ItemsCheckedBubblesQuery from '../QueryTypes/ItemsCheckedBubbles';
import ItemsCheckedNotOnBobtail from '../QueryTypes/ItemsCheckedNotOnBobtail';
import MissingItemAfterHours from '../QueryTypes/MissingItemAfterHours';
import MultipleItems from '../QueryTypes/MultipleItems';
import NonRoutableEvents from '../QueryTypes/NonRoutableEvents';
import NoOriginOrDestination from '../QueryTypes/NoOriginOrDestination';
import NullShipmentDate from '../QueryTypes/NullShipmentDate';
import OpenEquipmentLease from '../QueryTypes/OpenEquipmentLease';
import OriginDestinationAtSameLocation from '../QueryTypes/OriginDestinationAtSameLocation';
import ShipmentMissingConfirmedEvents from '../QueryTypes/ShipmentMissingConfirmedEvents';
import ShipmentsNotFromTemplate from '../QueryTypes/ShipmentsNotFromTemplate';
import TerminationLocationMismatch from '../QueryTypes/TerminationLocationMismatch';





const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    }
}));

export default function AddQueryModal(props) {
    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState({});
    const [queryParameters, setQueryParameters] = useState({});
    const [editMode, setEditMode] = useState(false);

    const classes = useStyles();

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleUpdateState(name, value);
    };

    const handleChangeCheckbox = ({ target }) => {
        const { name, checked } = target;
        var value;
        if (checked) {
            value = 1
        } else {
            value = 0
        }
        handleUpdateState(name, value);
    };

    function handleUpdateState(name, value) {
        if (["query_type", "sql", "title", "status"].includes(name)) {
            setFormState((prev) => ({
                ...prev,
                [name]: value
            }));
        } else {
            setQueryParameters((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    }

    const resetValues = () => {
        setFormState({})
        setQueryParameters({})
        setLoading(false)
    }

    const handleClose = () => {
        resetValues();
        props.closeAddQueryModal();
    }
    const handleDelete = () => {
        props.deleteQuery(props.query._id.$oid);
    }
    const handleSubmitForm = (event) => {
        event.preventDefault();
        setLoading(true)
        if (editMode) {
            props.updateQuery(props.query._id.$oid, formState, queryParameters);
        } else {
            props.createQuery(formState, queryParameters);
        }
    }

    const queryTypes = [
        { "value": "amount_type", "text": "Amount Type" },
        { "value": "custom", "text": "Custom Query" },
        { "value": "items_checked", "text": "Items Bubble Checked" },
        { "value": "item_event_count", "text": "Verify Items For Events" },
        { "value": "items_not_on_bobtail", "text": "Verify Items For Bobtail Events" },
        { "value": "term_loc_mismatch", "text": "Verify Import/Export Termination Locations" },
        { "value": "import_export_termination_event", "text": "Verify Import/Export Deliver/Pickup Events" },
        { "value": "multiple_items", "text": "Duplicate Items on Shipment" },
        { "value": "item_in_wrong_division", "text": "Item in Wrong Division" },
        { "value": "bill_to_in_wrong_division", "text": "BillTo in Wrong Division" },
        { "value": "deliver_pickup_at_same_location", "text": "Deliver/Pickup At Same Location On Different Shipments" },
        { "value": "no_origin_or_destination", "text": "No Origin or Destination" },
        { "value": "item_amounts", "text": "Item Amounts" },
        { "value": "null_shipment_date", "text": "No Shipment Date" },
        { "value": "days_since_drivers_last_move", "text": "Days Since Drivers Last Move" },
        { "value": "hours_since_last_confirmed", "text": "Hours Since Last Confirmed Event" },
        { "value": "missing_confirmed_event", "text": "Shipments Missing Confirmed Event" },
        { "value": "confirmed_events_at_site", "text": "Confirmed Events At Site" },
        { "value": "origin_destination_at_same_location", "text": "Origin and Destination At Same Location" },
        { "value": "driver_routed_to_cancelled_shipment", "text": "Driver Routed To Cancelled Shipment" },
        { "value": "non_routable_events", "text": "Non-Routable Events on Shipment" },
        { "value": "export_first_drop_not_destination", "text": "Export First Drop Not Destination" },
        { "value": "missing_item_after_x_hours", "text": "Missing Item After X Minutes At Location" },
        { "value": "open_equipment_lease", "text": "Open Equipment Leases on Completed Shipments" },
        { "value": "items_at_origin_or_destination", "text": "Items at Origin or Destination" },
        { "value": "event_times_overlap", "text": "Event Times Overlap" },
        { "value": "shipments_not_from_template", "text": "Shipments Not Created From Template" }
    ]

    const queryOptions = () => {
        return queryTypes.map((type, index) =>
            <option key={index} value={type.value}>{type.text}</option>
        );
    }

    function handleShow() {
        if (Object.keys(props.query).length === 0) {
            setEditMode(false)
            const evt = { target: { name: "status", checked: true } }
            handleChangeCheckbox(evt)
        } else {
            setEditMode(true)
            handleUpdateState("query_type", props.query.query_type)
            handleUpdateState("status", props.query.status)
            handleUpdateState("title", props.query.title)
            handleUpdateState("sql", props.query.sql)
            var value;
            for (value in props.query.values) {
                handleUpdateState(value, props.query.values[value])
            }
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            onExited={resetValues}
            backdrop="static"
            size="lg"
            keyboard={false}
            onShow={handleShow}
        >
            <Modal.Header>
                {editMode ?
                    <Modal.Title>Edit Validation Widget</Modal.Title> :
                    <Modal.Title>Add Validation Widget</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitForm}>
                    <Row>
                        <Col>
                            <InputLabel id="widget-type-label" required>Widget Type</InputLabel>
                            <Select
                                native
                                labelId="widget-type-label"
                                id="query_type"
                                name="query_type"
                                value={formState.query_type}
                                onChange={handleChange}
                                fullWidth
                                required
                            >
                                <option aria-label="None" />
                                {queryOptions()}
                            </Select>
                        </Col>
                    </Row>
                    <hr />
                    {formState.query_type != undefined && formState.query_type.length > 1 ?
                        <Row>
                            <Col>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    key="title"
                                    name="title"
                                    onChange={handleChange}
                                    helperText="This will show up as the header of the widget"
                                    value={formState.title}
                                />
                            </Col>
                        </Row> : null
                    }
                    <br />
                    {formState.query_type == "amount_type" ? <AmountTypeQuery handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "custom" ? <CustomQuery handleChange={handleChange} sql={formState.sql} /> : null}
                    {formState.query_type == "items_checked" ? <ItemsCheckedBubblesQuery handleChange={handleChange} handleChangeCheckbox={handleChangeCheckbox} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "item_event_count" ? <ItemEventCountMismatch handleChange={handleChange} handleChangeCheckbox={handleChangeCheckbox} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "items_not_on_bobtail" ? <ItemsCheckedNotOnBobtail handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "term_loc_mismatch" ? <TerminationLocationMismatch handleChange={handleChange} handleChangeCheckbox={handleChangeCheckbox} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "import_export_termination_event" ? <ImportExportTerminationEvent handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "multiple_items" ? <MultipleItems handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "item_in_wrong_division" ? <ItemInWrongDivision handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "bill_to_in_wrong_division" ? <BillToInWrongDivision handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "deliver_pickup_at_same_location" ? <DeliverPickupAtSameLocation handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "no_origin_or_destination" ? <NoOriginOrDestination handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "item_amounts" ? <ItemAmounts handleChange={handleChange} handleChangeCheckbox={handleChangeCheckbox} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "null_shipment_date" ? <NullShipmentDate handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "days_since_drivers_last_move" ? <DaysSinceDriversLastRoutedMove handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "hours_since_last_confirmed" ? <HoursSinceLastConfirmed handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "missing_confirmed_event" ? <ShipmentMissingConfirmedEvents handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "confirmed_events_at_site" ? <ConfirmedEventsAtSite handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "origin_destination_at_same_location" ? <OriginDestinationAtSameLocation handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "driver_routed_to_cancelled_shipment" ? <DriverRoutedToCancelledShipment handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "non_routable_events" ? <NonRoutableEvents handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "export_first_drop_not_destination" ? <ExportFirstDropNotDestination handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "missing_item_after_x_hours" ? <MissingItemAfterHours handleChange={handleChange} handleChangeCheckbox={handleChangeCheckbox} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "open_equipment_lease" ? <OpenEquipmentLease handleChange={handleChange} handleChangeCheckbox={handleChangeCheckbox} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "items_at_origin_or_destination" ? <ItemsAtOriginOrDestination handleChange={handleChange} handleChangeCheckbox={handleChangeCheckbox} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "event_times_overlap" ? <EventTimesOverlap handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    {formState.query_type == "shipments_not_from_template" ? <ShipmentsNotFromTemplate handleChange={handleChange} queryParameters={queryParameters} /> : null}
                    <Modal.Footer>
                        {loading ? <Loader /> :
                            <>
                                {editMode && formState.status === 0 ?
                                    <Button variant="outlined" color="secondary" onClick={handleDelete} className="mr-3">Delete Widget</Button> :
                                    null
                                }
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formState.status === 1}
                                            onChange={handleChangeCheckbox}
                                            name="status"
                                            color="primary"
                                        />
                                    }
                                    label="Enabled"
                                />
                                <Button variant="contained" color="info" onClick={handleClose} className="mr-3">Close</Button>
                                <Button variant="contained" color="primary" type="submit">{editMode ? "Save Changes" : "Create Widget"}</Button>
                            </>
                        }

                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}