import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AmountTypeDropdown from '../Selectors/AmountType'

export default function ItemsCheckedBubblesQuery(props) {

    const [inverted, setInverted] = useState(props.queryParameters.inverted);

    useEffect(() => {
        var sql;
        if (inverted) {
            sql = `select distinct de_shipment_id as 'Shipment ID', di_description as 'Item Description' from DBA.disp_ship left outer join DBA.disp_events on disp_ship.ds_id = disp_events.de_shipment_id left outer join DBA.companies on companies.co_id = de_site left outer join DBA.disp_items on disp_items.di_shipment_id = disp_ship.ds_id where de_event_type in ('R', 'N') and terminationlocation = 'T' and de_conf = 'T' and (di_pu_event is not null or di_del_event is not null) and <date> and AmountType in (<amount_type>) and disp_ship.ds_status not in ('C', 'D') order by de_shipment_id desc`
        } else {
            sql = `select distinct de_shipment_id as 'Shipment ID', di_description as 'Item Description' from DBA.disp_ship left outer join DBA.disp_events on disp_ship.ds_id = disp_events.de_shipment_id left outer join DBA.companies on companies.co_id = de_site left outer join DBA.disp_items on disp_items.di_shipment_id = disp_ship.ds_id where <date> and AmountType in (<amount_type>) and di_pu_event is null and di_del_event is null and disp_ship.ds_status not in ('C', 'D') order by de_shipment_id desc`
        }
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [inverted])

    const handleChange = (event) => {
        props.handleChange(event);
    };

    const handleInverted = ({ target }) => {
        const { name, checked } = target;
        var value;
        if (checked) {
            value = 1
        } else {
            value = 0
        }
        setInverted(value)
        props.handleChangeCheckbox({target});
    };

    const descriptionText = () => {
        if (inverted) {
            return "Items of these types should NEVER be assigned to an event(have the bubble unchecked). This widget will show you any items on completed shipments that are assigned to events that shouldn't be."
        } else {
            return "Items of these types should ALWAYS be assigned to an event(have the bubble checked). This widget will show you any items that aren't assigned as they should be."
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={inverted === 1}
                                onChange={handleInverted}
                                name="inverted"
                                color="primary"
                            />
                        }
                        label="Reverse"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="align-self-center">{descriptionText()}</Col>
            </Row>
            <Row>
                <Col>
                    <AmountTypeDropdown handleChange={handleChange} queryParameters={props.queryParameters} multiple required />
                </Col>
            </Row>
        </div>
    );
}