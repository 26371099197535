import React, { useState, useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import UserManagementTab from './UserManagementTab';
import ShipmentValidationTab from './ShipmentValidationTab';
import MissingImagesTab from './MissingImagesTab';
import PTTab from './PTTab';
import LogoTab from './LogoTab';

export default function AdminPage (props) {
    const [key, setKey] = useState('user-management');

    useEffect(() => {
        setKey(props.activeTab)
    }, []);

    return (
        <Jumbotron>
            <h1>Admin</h1>
            <hr /><br />
            <Tabs
                unmountOnExit
                activeKey={key}
                onSelect={(k) => setKey(k)}>
                <Tab eventKey="user-management" title="User Management">
                    <UserManagementTab />
                </Tab>
                <Tab eventKey="shipment-validation" title="Shipment Validation">
                    <ShipmentValidationTab />
                </Tab>
                <Tab eventKey="missing-images" title="Missing Images" disabled>
                    <MissingImagesTab />
                </Tab>
                <Tab eventKey="pt-integration" title="PT Integration">
                    <PTTab />
                </Tab>
                <Tab eventKey="display" title="Logo">
                    <LogoTab />
                </Tab>
            </Tabs>
        </Jumbotron>
    );
}