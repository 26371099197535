import React from 'react';
import QueryDropdown from './SelectorTypes/QueryDropdown';


export default function SiteDropdown(props) {
    return (<QueryDropdown
        queryParameters={props.queryParameters}
        handleChange={props.handleChange}
        multiple={props.multiple}
        required={props.required}
        disabled={props.disabled}
        displayName={props.displayName === undefined ? "Site" : props.displayName}
        name={props.name === undefined ? "site" : props.name}
        sql="SELECT co_id as value, co_name as name FROM DBA.companies where co_status = 'K' order by name ASC"
    />)
}
