import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';



export default function ShipmentsNotFromTemplate(props) {

    useEffect(() => {
        const sql = `select ds_id as 'Shipment ID' from (
            select disp_ship.ds_id, ds_status, LIST(mod_value ORDER BY mod_id) as mods
            from dba.disp_ship
            left join dba.modlog_ship on modlog_ship.ds_id = disp_ship.ds_id
            where <date>
            group by disp_ship.ds_id, ds_status
            order by disp_ship.ds_id
            ) a
            where a.mods not like '%CREATED FROM SHIPMENT%' AND ds_status NOT IN('A', 'C', 'D')`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will look for shipemnts not created from a Template. This could mean the shipment has no parent shipment at all, or the parent shipment isn't a Template.</Col>
            </Row>
        </>
    );
}