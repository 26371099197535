import React, { useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SiteDropdown from '../Selectors/Site'


export default function ConfirmedEventsAtSite(props) {

    const [site, setSite] = useState();

    useEffect(() => {
        setSite(props.queryParameters.site)
    }, [])

    useEffect(() => {
        const sql = `select distinct ds_id as 'Shipment ID' from dba.disp_ship left join dba.disp_events on ds_id = de_shipment_id where de_site in (<site>) and <date> and ds_status not in ('C', 'D') and de_conf = 'T' order by ds_id desc`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        if (event.target.name === 'site') {
            setSite(event.target.value)
        }
        props.handleChange(event);
    };

    return (
        <>
            <Row>
                <Col className="align-self-center">This will show you shipments where there are confirmed events at these sites.</Col>
            </Row>
            <Row>
                <Col>
                    <SiteDropdown handleChange={handleChange} queryParameters={props.queryParameters} displayName="Sites" multiple required/>
                </Col>
            </Row>
        </>
    );
}