import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function ExportFirstDropNotDestination(props) {

    useEffect(() => {
        const sql = `select de_shipment_id as 'Shipment ID' from DBA.disp_events left join dba.disp_ship left join ( SELECT ds_id, ds_findest_id, min(de_ship_seq) as first_drop FROM dba.disp_ship left join DBA.disp_events where de_event_type = 'R' and <date> and movecode='E' and BobtailOriginEvent is null and BobtailDestinationEvent is null group by ds_id, ds_findest_id having count(de_id) > 1) a on a.ds_id = de_shipment_id and de_ship_seq = first_drop where de_site != a.ds_findest_id and ds_status not in ('C', 'D') order by de_shipment_id desc`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will look for Export shipments where the first drop is not set as the destination.</Col>
            </Row>
        </>
    );
}