import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export default function NoOriginOrDestination(props) {

    useEffect(() => {
        const sql = `SELECT ds_id as 'Shipment ID' FROM "DBA"."disp_ship" WHERE (ds_origin_id IS NULL OR ds_findest_id IS NULL) AND <date> AND ds_status not in ('C', 'A', 'D')`
        const evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
    }, [])

    return (
        <>
            <Row>
                <Col className="align-self-center">This will look for shipments where the shipment doesn't have an origin or destination set.</Col>
            </Row>
        </>
    );
}