import React, { useState, useEffect, useContext }  from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RefreshIcon from '@material-ui/icons/Refresh';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import API from '../../../api';
import { AccountContext } from '../../auth/Accounts';
import { BlackflyContext } from '../../contexts/Blackfly';
import Loader from '../../Loading';
import { withSnackbar } from "../../HOC/Alert";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginLeft: theme.spacing(1),
    },
}));

function ValidationQuery (props) {
    const [queryResults, setQueryResults] = useState([])
    const [resultRows, setResultRows] = useState([])
    const [resultHeaders, setResultHeaders] = useState([])
    const [border, setBorder] = useState("light")
    const [loading, setLoading] = useState(true)
    const [errorText, setErrorText] = useState("")

    const { getSession } = useContext(AccountContext);
    const { blackflyAPIKey } = useContext(BlackflyContext);

    const classes = useStyles();

    function execute_sql() {
        getSession()
            .then(session => {
                setLoading(true);
                clearData();
                const payload = { sql: props.sql }
                API.post("/execute_sql", payload, {
                    headers: { "Authorization": session.idToken.jwtToken },
                    params: { blackflyAPIKey: blackflyAPIKey }
                })
                    .then(res => {
                        setLoading(false);
                        setQueryResults(res.data);
                        handleSetOnParent(res.data);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response !== undefined) {
                            console.log(err.response.data);
                            setErrorText(err.response.data)
                            props.setResultsOnParent(props.title, err.response.data);
                        } else {
                            console.log(err);
                            setErrorText("Unknown Error")
                            props.setResultsOnParent(props.title, "Unknown Error");
                        }
                    })
            })
    }

    function clearData() {
        setQueryResults([]);
        setErrorText("");
    }

    function handleSetOnParent(data) {
        if (data === undefined || data.length === 0) {
            props.setResultsOnParent(props.title, "No Results");
        } else {
            props.setResultsOnParent(props.title, data);
        }
    }

    useEffect(() => {
        execute_sql();
    }, []);

    useEffect(() => {
        if (queryResults === undefined || queryResults.length === 0) {
            setResultHeaders([])
        } else {
            const keys = Object.keys(queryResults[0])
            setResultHeaders(keys);
        }
    }, [queryResults]);

    useEffect(() => {
        const rows = queryResults.map((result_row, index) => 
            <TableRow key={index}>{getRowData(result_row)}</TableRow>
            );
        setResultRows(rows)
    }, [resultHeaders]);

    function getRowData(result_row) {
        return resultHeaders.map((header, index) => {
            // return (
            //         <TableCell key={index}>{result_row[header]}
            //             <CopyToClipboard text={result_row[header]}>
            //                 <IconButton size="small" className={classes.margin}>
            //                     <FileCopyIcon fontSize="small"/>
            //                 </IconButton>
            //             </CopyToClipboard>
            //         </TableCell>
            //         )
            if (header === "Shipment ID" || header === "shipment_id") {
                return (
                    <TableCell key={index}>{result_row[header]}
                        <CopyToClipboard text={result_row[header]} 
                            onCopy={() => props.showAlert("Copied " + result_row[header] + " to clipboard!", "success", 1500)}>
                            <IconButton size="small" className={classes.margin}>
                                <FileCopyOutlinedIcon fontSize="small" />
                            </IconButton>
                        </CopyToClipboard>
                    </TableCell>
                )
            } else {
                return (
                    <TableCell key={index}>{result_row[header]}</TableCell>
                )
            }
        })
    }
    function getHeaderRow() {
        return resultHeaders.map((header, index) => {
            return (<TableCell key={index}>{header}</TableCell>)
        })
    }

    useEffect(() => {
        if (queryResults.length <= 3) {
            setBorder("light")
        } else if (queryResults.length <= 6) {
            setBorder("warning")
        } else {
            setBorder("danger")
        }
    }, [queryResults]);

    useEffect(() => {
        if (errorText) {
            setBorder("danger")
        }
    }, [errorText]);

    const validationTableStyle = {
        maxHeight: "200px",
        overflow: "auto"
    }
    const errorStyle = {
        color: "red"
    }

    return (
        <Card border={border} style={{ borderWidth: '2px' }}>
            <Card.Header as="h6"><b>{props.title}</b></Card.Header>
            <Card.Body>
                {loading ? <Loader /> : null}
                {errorText ? <Card.Text style={errorStyle}>{errorText}</Card.Text> : null}
                {queryResults.length === 0 && !errorText && !loading ? 
                <Card.Text>
                    No Results
                </Card.Text> :
                <div style={validationTableStyle}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="query results">
                            <TableHead>
                                <TableRow>
                                    {getHeaderRow()}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {resultRows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> }
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <Tooltip title="Refresh Data" aria-label="refresh" placement="left-start">
                            <Fab color="primary" size="small" disabled={loading}>
                                <RefreshIcon onClick={execute_sql} />
                            </Fab>
                        </Tooltip>
                    </Col>
                    <Col sm={3} className="align-self-center">
                        <small className="text-muted">Rows: {queryResults.length}</small>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
}

export default withSnackbar(ValidationQuery)