import React, { useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SiteDropdown from '../Selectors/Site'


export default function DeliverPickupAtSameLocation(props) {

    useEffect(() => {
        const sql = `select og.shipment_id as 'Shipment ID', prev.shipment_id as 'Previous Shipment ID', em_fn + ' ' + em_ln + ' ('+em_ref+')' as Driver from ( select row_number() OVER (ORDER BY de_driver, arrival_time, de_driver_seq) as rowid, de_driver as driver, de_event_type as event_type, de_shipment_id as shipment_id, de_arrdate + de_arrtime as arrival_time, de_site as site FROM DBA.disp_events JOIN DBA.disp_ship on DBA.disp_ship.ds_id = DBA.disp_events.de_shipment_id JOIN DBA.companies on companies.co_id = DBA.disp_events.de_site where <date> and de_driver > 0 and ds_status not in ('C', 'D') and (terminationlocation = 'F' or terminationlocation is null) and de_site not in (<site>) and de_conf = 'T' ) og LEFT JOIN ( select row_number() OVER (ORDER BY de_driver, arrival_time, de_driver_seq) as rowid, de_driver as driver, de_event_type as event_type, de_shipment_id as shipment_id, de_arrdate + de_arrtime as arrival_time, de_site as site FROM DBA.disp_events JOIN DBA.disp_ship on DBA.disp_ship.ds_id = DBA.disp_events.de_shipment_id JOIN DBA.companies on companies.co_id = DBA.disp_events.de_site where <date> and de_driver > 0 and ds_status not in ('C', 'D') and (terminationlocation = 'F' or terminationlocation is null) and de_site not in (<site>) and de_conf = 'T' ) prev on prev.rowid = og.rowid - 1 left join dba.employees on employees.em_id = og.driver WHERE og.event_type = 'P' and prev.event_type = 'D' and og.shipment_id != prev.shipment_id and og.driver = prev.driver and og.site = prev.site`
        var evt = { target: { name: "sql", value: sql } }
        props.handleChange(evt)
        var site = props.queryParameters.site
        if (site === undefined) {
            site = [-1]
        }
        evt = { target: { name: "site", value: site } }
        props.handleChange(evt)
    }, [])

    const handleChange = (event) => {
        props.handleChange(event);
    };

    return (
        <>
            <Row>
                <Col className="align-self-center">This will look at a drivers itinerary and find a deliver event followed by a pickup event at the same location but on different shipments.
                You can optionally ignore certain sites.</Col>
            </Row>
            <Row>
                <Col>
                    <SiteDropdown handleChange={handleChange} queryParameters={props.queryParameters} displayName="Sites" multiple/>
                </Col>
            </Row>
        </>
    );
}